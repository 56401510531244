<template>
  <Dropdown
    v-model="value"
    :options="languages"
    placeholder="Select a Country"
    optionLabel="title"
    class="md:w-2rem"
  >
    <template #value="slotProps">
      <div v-if="slotProps.value" class="flex align-items-center">
        <img
          :src="`${slotProps.value.flag}`"
          :class="`mr-2 flag`"
          style="width: 18px"
        />
        <div>{{ slotProps.value.title }}</div>
      </div>
      <div v-else>
        {{ slotProps.placeholder }}
      </div>
    </template>
    <template #option="slotProps">
      <div
        class="flex align-items-center"
        @click="
          setLanguage(
            slotProps.option.language,
            slotProps.option.title,
            slotProps.option.flag
          )
        "
      >
        <img
          :src="`${slotProps.option.flag}`"
          :class="`mr-2 flag`"
          style="width: 18px"
        />
        <div>{{ slotProps.option.title }}</div>
      </div>
    </template>
  </Dropdown>
</template>
<script>
import Dropdown from "primevue/dropdown";

export default {
  name: "MultiLanguage",
  components: { Dropdown },
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/vn.svg"),
          language: "vi",
          title: "Tiếng Việt",
        },
        {
          flag: require("@/assets/images/flags/sh.svg"),
          language: "en",
          title: "English",
        },
      ],
      current_language: this.$i18n.locale,
      countryName: null,
      flag: null,
      value: null,
    };
  },
  methods: {
    setLanguage(locale, country, flag) {
      this.$i18n.locale = locale;
      this.current_language = locale;
      this.countryName = country;
      this.flag = flag;
      localStorage.setItem("lang", locale);
    },
  },
  mounted() {
    const currentLanguage = localStorage.getItem("lang");

    if (currentLanguage) {
      this.value = this.languages.find((x) => x.language === currentLanguage);
      this.setLanguage(
        currentLanguage,
        this.languages.find((lang) => lang?.language === currentLanguage).title,
        this.languages.find((lang) => lang?.language === currentLanguage).flag
      );
    } else {
      this.value = this.languages.find((x) => x.language === this.$i18n.locale);
      this.countryName = this.value.title;
      this.flag = this.value.flag;
    }
  },
};
</script>
