<template>
  <div class="center-box--without-bg" v-if="step === 1">
    <h5>{{ $t("buy_page.title") }}</h5>
  </div>
  <div v-if="step === 2">
    <span class="btn btn-neutral-400" @click="step = 1">
      <span><i class="fas fa-arrow-left mr-1"></i>{{ $t("buy_page.term.back") }}</span>
    </span>
  </div>
  <form @submit.prevent="submitAsync" ref="form">
    <div v-if="step === 1" class="center-box blue-top-border">
      <h6 style="color: #0080c0">{{ $t("buy_page.software_info.title") }}</h6>
      <br />
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="project">{{ $t("buy_page.software_info.software_name.label")
              }}<span class="cl-red">*</span></label>
            <SelectBox v-model="orderObj.project_id" ref="selectProject" :placeHolder="softWarePlaceholder" id="project"
              @onchange="projectChangedEvent">
            </SelectBox>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="modules">{{
              $t("buy_page.software_info.package")
            }}</label>
            <Dropdown v-model="orderObj.project_package_id" :options="uiData.projectPackages" optionLabel="name"
              optionValue="id" @change="projectPackageChangedEvent" class="w-full md:w-14rem" />
          </div>
        </div>

        <!-- Modules -->
        <div class="col-md-12">
          <div v-if="uiData.projectModules.length > 0" class="projectModuleContainer">
            <div class="form-group">
              <label for="modules">Modules</label>

              <div class="modules-container">
                <div v-for="item in uiData.projectModules" :key="item.name" class="modules-container__item">
                  <input type="checkbox" v-model="item.selected" :id="'module-' + item.name"
                    :disabled="item.disabled" />
                  <label :for="'module-' + item.name">{{ item.name }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Aff code-->
        <div class="col-md-12">
          <div class="form-group">
            <label for="affiliate_code">
              {{ $t("buy_page.software_info.aff_code") }}
              <div v-show="onLoadingAffInfo" class="spinner-border text-secondary spinner-border-sm" role="status"
                style="margin-left: 5px">
                <span class="sr-only">Loading...</span>
              </div>
            </label>
            <input v-model="orderObj.affiliate_code" class="form-control" id="affiliate_code"
              @input="affCodeChangedEvent" />

            <div v-if="affMessage != ''">
              <br />
              <p>{{ affMessage }}</p>
            </div>
          </div>
        </div>

        <!-- Total -->
        <div class="col-md-12">
          <br />
          <h6 style="color: #111111">
            {{ $t("buy_page.software_info.total") }}:
            {{ formatVnPrice(orderObj.actual_price) }}
          </h6>
        </div>
      </div>
    </div>

    <!-- Address -->
    <div v-if="step === 1" class="center-box blue-top-border">
      <div class="address">
        <h6 style="color: #0080c0">{{ $t("buy_page.customer_info.title") }}</h6>
        <br />
        <p style="color: gray">{{ $t("buy_page.customer_info.title") }}</p>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="displayName">{{ $t("buy_page.customer_info.user_name")
                }}<span class="cl-red">*</span></label>
              <input type="text" class="form-control" id="displayName" v-model="customerObj.display_name" required
                ref="displayNameInput" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="phoneNum">{{
                $t("buy_page.customer_info.phone")
              }}</label>
              <input type="text" class="form-control" id="phoneNum" v-model="customerObj.phone_number" />
            </div>
          </div>
        </div>
        <!-- <el-checkbox v-model="notInVietNam" label="Tôi không ở Việt Nam" size="large" /> -->
        <input type="checkbox" id="notInVietNam" v-model="notInVietNam" />
        <label for="notInVietNam" class="form-label">&nbsp;{{ $t("buy_page.customer_info.not_in_vi") }}</label>

        <br /><br />
        <div class="row">
          <div class="col-md-4" v-if="!notInVietNam">
            <div class="form-group">
              <label for="province">{{ $t("buy_page.customer_info.province.label")
                }}<span class="cl-red">*</span></label>
              <!-- <select id="province" ref="province" class="form-control" v-model="customerObj.province"
                @change="provinceChanged" required>
                <option>{{$t('buy_page.customer_info.province.placeholder')}}</option>
                <option v-for="item in provinceList" :value="item" v-bind:key="item">{{ item }}</option>
              </select> -->
              <Dropdown v-model="customerObj.province" filter :options="provinceListMap" optionLabel="name"
                optionValue="value" :placeholder="$t('buy_page.customer_info.province.placeholder')"
                @change="provinceChanged" class="w-full md:w-14rem" />
            </div>
          </div>
          <div class="col-md-4" v-if="!notInVietNam">
            <div class="form-group">
              <label for="district">{{ $t("buy_page.customer_info.district.label")
                }}<span class="cl-red">*</span></label>
              <Dropdown v-model="customerObj.district" filter :options="districtListMap" optionLabel="name"
                optionValue="value" :placeholder="$t('buy_page.customer_info.district.placeholder')"
                class="w-full md:w-14rem" @change="districtChanged" />
            </div>
          </div>
          <div class="col-md-4" v-if="!notInVietNam">
            <div class="form-group">
              <label for="ward">{{ $t("buy_page.customer_info.ward.label")
                }}<span class="cl-red">*</span></label>
              <Dropdown v-model="customerObj.ward" filter :options="wardListMap" optionLabel="name" optionValue="value"
                :placeholder="$t('buy_page.customer_info.ward.placeholder')" class="w-full md:w-14rem" />
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="address">{{ $t("buy_page.customer_info.address") }}
            <span class="cl-red">*</span></label>
          <input type="text" class="form-control" id="address" v-model="customerObj.addressText" required />
        </div>
        <div class="form-group">
          <label for="tax">{{ $t("buy_page.tax.tax_code") }}</label><br />
          <small style="margin-bottom: 10px; display: block" v-html="$t('buy_page.tax.content')"></small>
          <input type="text" class="form-control" id="tax" v-model="customerObj.tax_code" />
        </div>
      </div>
    </div>

    <!-- Rule -->
    <div v-if="step === 2" class="center-box blue-top-border">
      <div class="warning-container">
        <h6 style="color: #0080c0">{{ $t("buy_page.term.title") }}</h6>
        <br />
        <div class="inline">
          <p v-html="$t('buy_page.term.content')"></p>
          <p v-html="$t('buy_page.term.content2')"></p>
        </div>
      </div>
    </div>

    <div class="center-box--without-bg saving-wrapper" v-if="step === 2">
      <div style="margin: 5px 20px 0px 0px" class="flex align-items-baseline">
        <!-- <el-checkbox v-model="argeeRule" label="Tôi đã đọc và đồng ý điều khoản trên" size="large" /> -->
        <input type="checkbox" id="argeeCheckbox" style="margin: auto 0" v-model="argeeRule" />
        <label for="argeeCheckbox" style="margin: auto 10px">{{
          $t("buy_page.term.agree_rule")
        }}</label>
      </div>

      <button type="submit" class="btn btn-success btn-payment" :class="{ 'loading-btn': onSubmiting }"
        :disabled="!argeeRule">
        <div class="spinner-border text-light spinner-border-sm" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <span>{{ $t("buy_page.term.payment") }}</span>
      </button>
    </div>
    <div class="center-box--without-bg saving-wrapper" v-if="step === 1">
      <span class="btn btn-success btn-payment" @click="nextStep()">
        <span>{{ $t("buy_page.term.next") }}</span>
      </span>
    </div>
  </form>
</template>

<script>
import SelectBox from "@/components/SelectBox.vue";
import * as db from "@/services/customerService";
import { getAddressDataAsync } from "@/services/addressProvider";
import {
  getCurrentUserAsync,
  updateCurrentUserAsync,
} from "@/services/authService";
import { formatPrice } from "@/helpers/strHelper";
import Dropdown from "primevue/dropdown";
export default {
  name: "BuyPage",
  components: {
    SelectBox,
    Dropdown,
  },
  computed: {
    projectModuleSelected() {
      return this.uiData.projectModules.map((item) => item.selected);
    },
    softWarePlaceholder() {
      return this.$t("buy_page.software_info.software_name.placeholder");
    },
    provinceListMap() {
      return this.provinceList.map((item) => {
        return { name: item, value: item };
      });
    },
    districtListMap() {
      return this.districtList.map((item) => {
        return { name: item, value: item };
      });
    },
    wardListMap() {
      return this.wardList.map((item) => {
        return { name: item, value: item };
      });
    },
  },
  watch: {
    projectModuleSelected() {
      this.caculatePriceAsync();
    },
  },
  data() {
    return {
      orderObj: {
        modify_license_mode: "NEW",
        project_id: null,
        project_module_names: [],
        project_package_id: null,
        actual_price: 0,
        affiliate_code: null,
      },
      affMessage: "",
      uiData: {
        projectModules: [], // {name: '', selected: false}
        projectPackages: [],
        projects: [],
      },
      customerObj: {
        province: null,
        district: null,
        ward: null,
        addressText: null,
      },
      addressData: {},
      provinceList: [],
      districtList: [],
      wardList: [],
      notInVietNam: false,
      onLoadingAffInfo: false,
      onSubmiting: false,
      argeeRule: false,
      step: 1,
    };
  },
  updated() {
    document.title = this.$t("buy_page.document_title");
  },
  async mounted() {
    await this.loadProjectSelectBoxAsync();

    if (this.$route.query.ref) {
      this.orderObj.affiliate_code = this.$route.query.ref;
    }
    this.customerObj = (await getCurrentUserAsync()).data;
    this.addressData = await getAddressDataAsync();

    // Get all province
    this.provinceList = Object.keys(this.addressData);
    this.provinceList.sort((a, b) => a.localeCompare(b));

    // Fill address from raw data
    if (
      this.customerObj.address != null &&
      this.customerObj.address.split("").length > 0
    ) {
      const addressParts = this.customerObj.address.split(",");

      if (addressParts.length == 4) {
        this.customerObj.addressText = addressParts[0].trim();
        this.customerObj.province = addressParts[3].trim();
        this.provinceChanged();

        this.customerObj.district = addressParts[2].trim();
        this.districtChanged();

        this.customerObj.ward = addressParts[1].trim();
        this.notInVietNam = false;
      } else {
        this.customerObj.addressText = this.customerObj.address;
        this.notInVietNam = true;
      }
    }
  },
  methods: {
    nextStep() {
      /** too tired, lazy to refactor code :(( **/
      const form = this.$refs.form;
      if (!form.checkValidity()) {
        form.reportValidity();
      } else if (!this.orderObj.project_id) {
        this.$toast.error(this.$t("my_account.noti.software_name_required"));
        return;
      } else {
        if (!this.notInVietNam) {
          if (!this.customerObj.province) {
            this.$toast.error(this.$t("my_account.noti.province_required"));
            return;
          }

          if (!this.customerObj.district) {
            this.$toast.error(this.$t("my_account.noti.district_required"));
            return;
          }

          if (!this.customerObj.ward) {
            this.$toast.error(this.$t("my_account.noti.ward_required"));
            return;
          }
        }

        this.step = 2;
        const element = document.querySelector(".main-content-body");
        if (element) {
          element.scrollTo({
            top: 0,
          });
        }
      }
    },
    async submitAsync() {
      this.onSubmiting = true;

      // Standardize data
      let slectedModulesName = [];
      this.uiData.projectModules.forEach((item) => {
        if (item.selected) slectedModulesName.push(item.name);
      });
      this.orderObj.project_module_names = slectedModulesName;

      // Update user info
      if (this.notInVietNam)
        this.customerObj.address = this.customerObj.addressText;
      else
        this.customerObj.address =
          this.customerObj.addressText.replace(",", "") +
          ", " +
          this.customerObj.ward +
          ", " +
          this.customerObj.district +
          ", " +
          this.customerObj.province;

      const customerUpdateObj = {
        id: this.customerObj.id,
        display_name: this.customerObj.display_name,
        phone_number: this.customerObj.phone_number,
        address: this.customerObj.address,
        tax_code: this.customerObj.tax_code,
      };
      await updateCurrentUserAsync(customerUpdateObj);

      // Insert to db
      const result = await db.createOrderAsync(this.orderObj);

      if (result.success == true) {
        this.$toast.success(this.$t("buy_page.noti.success"));
        this.$router.push({
          name: "transfer",
          query: { order: result.data.transfer_content },
        });
      } else {
        this.$toast.error(result.message);
        this.step = 1;
      }

      this.onSubmiting = false;
    },

    // Events ------------------------------------------------------------------------------------
    projectChangedEvent() {
      const selectedProject = this.uiData.projects.find(
        (item) => item.id == this.orderObj.project_id
      );
      if (selectedProject === undefined) return;

      this.uiData.projectModules = [];
      this.uiData.projectPackages = [];

      selectedProject.modules.forEach((item) => {
        this.uiData.projectModules.push({
          name: item.name,
          price: item.price,
          selected: false,
        });
      });

      this.uiData.projectPackages = selectedProject.packages;
      if (this.uiData.projectPackages.length > 0) {
        this.orderObj.project_package_id = this.uiData.projectPackages[0].id;
        this.orderObj.actual_price = this.uiData.projectPackages[0].price;
      }
    },

    async affCodeChangedEvent() {
      await this.caculatePriceAsync();
    },

    async projectPackageChangedEvent() {
      await this.caculatePriceAsync();
    },

    async caculatePriceAsync() {
      try {
        const selectPackage = this.uiData.projectPackages.find(
          (item) => item.id == this.orderObj.project_package_id
        );
        this.orderObj.actual_price = parseInt(selectPackage.price);
        const selectedModules = this.uiData.projectModules.filter(
          (item) => item.selected && !item.disabled
        );
        selectedModules.forEach((item) => {
          this.orderObj.actual_price += parseInt(item.price);
        });

        if (this.orderObj.affiliate_code) {
          this.onLoadingAffInfo = true;
          const checker = await db.getAffiliateInfoAsync(
            this.orderObj.affiliate_code
          );

          if (checker.success == true) {
            const saleOffPercent = parseInt(
              process.env.VUE_APP_AFFILIATE_PERCENT_FOR_BUYER
            );
            if (this.orderObj.actual_price > 0) {
              this.orderObj.actual_price =
                ((100 - saleOffPercent) * this.orderObj.actual_price) / 100;
            }
          }

          this.affMessage = checker.message;

          this.onLoadingAffInfo = false;
        }
        // eslint-disable-next-line no-empty
      } catch { }
    },

    formatVnPrice(price) {
      return formatPrice(price * 1000);
    },

    provinceChanged() {
      this.wardList = [];
      if (this.addressData[this.customerObj.province]) {
        this.districtList = Object.keys(
          this.addressData[this.customerObj.province]
        );
        this.districtList.sort((a, b) => a.localeCompare(b));
      } else this.districtList = [];
      this.customerObj.district = null;
      this.customerObj.ward = null;
    },

    districtChanged() {
      if (
        this.addressData[this.customerObj.province][this.customerObj.district]
      ) {
        this.wardList =
          this.addressData[this.customerObj.province][
          this.customerObj.district
          ];
        this.wardList.sort((a, b) => a.localeCompare(b));
      } else this.wardList = [];
      this.customerObj.ward = null;
    },

    // Data loader -------------------------------------------------------------------------------
    async loadProjectSelectBoxAsync() {
      const result = await db.getCanBuyProjectsAsync();
      if (result.success == true) {
        this.uiData.projects = result.data;
        this.$refs.selectProject.updateSuggestList(result.data, "name", "id");
      }
    },
  },
};
</script>

<style scoped>
.modules-container {
  display: flex;
  flex-wrap: wrap;
  background: #f7f7f7;
  padding: 15px;
  border-radius: 5px;
}

.modules-container__item {
  padding: 5px 15px 5px 0px;
}

.modules-container__item input {
  margin-right: 6px;
}

.radioLicenseModeContainer input {
  margin-right: 5px;
  margin-bottom: 30px;
}

.btn-payment {
  margin: auto 0;
  max-height: 44px;
}
</style>
