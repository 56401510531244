export const MENU_CONFIG = [
    {
      id: 1,
      label: 'menu_items.dashboard',
      icon: 'fas fa-tachometer-alt',
      link: 'dashboard',
    },
    {
      id: 2,
      label: 'menu_items.buy',
      icon: 'fas fa-store',
      link: 'buy',
    },
    {
      id: 3,
      label: 'menu_items.license',
      icon: 'far fa-id-badge',
      link: 'license',
    },
    {
      id: 4,
      label: 'menu_items.affiliate',
      icon: 'fab fa-affiliatetheme',
      link: 'affiliate',
    },
    {
      id: 5,
      label: 'menu_items.support',
      icon: 'fas fa-headsetfas fa-headset',
      link: 'support',
    },
  
  ]
  