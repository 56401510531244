<template>
    <div class="center-box--without-bg">
      <h5>{{ $t('affiliate_detail_page.title') }}</h5>
    </div>

    <div class="center-box">
      <p style="color: #999999">{{ $t('affiliate_detail_page.content') }}</p>

      <br>
      <table>
        <tr>
          <td class="tb-header">{{ $t('affiliate_detail_page.withdraw_point') }}</td>
          <td><b>{{ affOrderObj.withdraw_point }}</b></td>
        </tr>
        <tr>
          <td class="tb-header">{{ $t('affiliate_detail_page.status') }}</td>
          <td><b>{{ affOrderObj.status }}</b></td>
        </tr>
      </table>
      <label>{{ $t('affiliate_detail_page.result') }}</label><br>
      <span v-html="affOrderObj.message"></span>
      <span v-if="!affOrderObj.message"><i>{{ $t('affiliate_detail_page.result_content') }}</i></span>
    </div>
</template>
  
<script>
  import { formatDateTimeString } from '@/helpers/strHelper';
  import { getAffiliateOrderDetailsAsync } from '@/services/customerService';
  
  export default {
    name: 'AffilateOrderPage',
    data() {
      return {
        affOrderObj: {}
      }
    },
    created() {
      document.title = 'Affiliate Order | User site';
    },

    async mounted() {
      const id = this.$route.query.id
      const result = await getAffiliateOrderDetailsAsync(id);

      if (result.success === true){
        this.affOrderObj = result.data;
      }
    },
  
    methods: {
      formatDateTimeStr(dateString){
          return formatDateTimeString(dateString);
      }
    }
  }
  </script>
  
  <style scoped>
.affiliate-input {
  max-width: 100px !important;
}

.success {
  color: #00d200;
}

.error {
  color: red;
}

.tb-header {
  margin-right: 15px;
  display: inline-block;
}

td {
  padding-bottom: 10px;
}
</style>