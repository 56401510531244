<template>
  <div class="auth bg-base d-flex flex-wrap">
    <div class="auth-left d-lg-block d-none">
      <div
        class="d-flex align-items-center flex-column h-100 justify-content-center"
      >
        <img src="../../assets/auth-img.png" alt="" class="img-bg" />
      </div>
    </div>

    <div
      class="auth-right py-32 px-24 d-flex flex-column justify-content-center"
    >
      <div class="max-w-464-px mx-auto w-100">
        <div>
          <a href="index.html" class="mb-40 max-w-290-px">
            <img src="../../assets/logo-gpm.png" alt="" style="height: 40px;"/>
          </a>
          <h4 class="mb-12">{{$t('sign_up.title')}}</h4>
          <p class="mb-32 text-secondary-light text-lg">
            {{$t('sign_up.content')}}
          </p>
        </div>
        <form @submit.prevent="submitReisger">
          <div class="icon-field mb-16">
            <input
              type="text"
               id="email"
              class="form-control h-56-px bg-neutral-50 radius-12"
              placeholder="Email"
               v-model="email"
               :class="{'is-invalid': emailError}"
               @input="resetError('email')"
            />
          </div>
          <div class="position-relative mb-20">
            <div class="icon-field">
              <input
                type="password"
                class="form-control h-56-px bg-neutral-50 radius-12"
                id="your-password"
                v-model="password"
                :placeholder="$t('sign_up.pass')"
                :class="{'is-invalid': passError}"
                 @input="resetError('password')"
              />
            </div>
            <span
              class="toggle-password ri-eye-line cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light"
               @click="togglePassword()"
            ></span>
          </div>
          <div class="icon-field mb-16">
            <input
              type="password"
              class="form-control h-56-px bg-neutral-50 radius-12"
              :placeholder="$t('sign_up.repassword')"
              v-model="repassword"
              :class="{'is-invalid': passError}"
               @input="resetError('password')"
            />
          </div>
          <div class="icon-field mb-16">
            <input
              type="text"
              class="form-control h-56-px bg-neutral-50 radius-12"
              :placeholder="$t('sign_up.full_name')"
               v-model="displayName"
            />
          </div>
          <div class="icon-field mb-16">
            <input
              type="text"
              class="form-control h-56-px bg-neutral-50 radius-12"
              :placeholder="$t('sign_up.fb_name')"
               v-model="fbName"
            />
          </div>
          <div class="icon-field mb-16">
            <input
              type="text"
              class="form-control h-56-px bg-neutral-50 radius-12"
              :placeholder="$t('sign_up.fb_link')"
               v-model="fbUrl"
            />
          </div>
          <div class="icon-field mb-16 error" v-if="errorMsg">
            <span>{{ errorMsg }}</span>
          </div>
          <div class="">
            <div class="d-flex justify-content-between gap-2">
              <div class="form-check style-check d-flex align-items-start">
                <input
                  class="form-check-input border border-neutral-300 mt-4"
                  type="checkbox"
                  id="remeber"
                  v-model="isAcceptedPolicy"
                />
                <label class="form-check-label" for="remeber"
                  >
                  {{$t('sign_up.term')}}
                </label>
              </div>
            </div>
          </div>
          <button
            type="submit"
            class="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32"
            :disabled="!isAcceptedPolicy"
          >
          {{$t('sign_up.submit')}}
          </button>      
          <div class="mt-32 text-center text-sm flex justify-between auth-footer px-2">
            <div class="flex items-center col-sm-7 col-12">
              <span class="redirect-register">
                {{$t('sign_up.re_signup')}}
              <router-link :to="{name: 'login'}" class="text-primary-600 fw-semibold">&nbsp;{{$t('sign_up.sign_in')}}</router-link>
            </span>
            </div>
            <div class="mb-0 col-sm-5 col-12 box-language">
              <MultiLanguage />         
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { registerAsync } from '@/services/authService';
import { isValidEmail } from '@/helpers/strHelper';
import MultiLanguage from '@/components/MultiLanguage.vue'

export default {
  name: 'RegisterPage',
  components: {
    MultiLanguage
  },
  data(){
      return {
          title: 'Register | User site',
          email: '',
          password: '',
          repassword: '',
          displayName: '',
          fbName: '',
          fbUrl: '',
          onSubmiting: false,
          errorMsg: '',
          emailError: false,
          passError : false,
          isAcceptedPolicy: false,
          showPassword: false
      }
  },
  created() {
      document.title = this.title;
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
      const passwordInput = document.querySelector('#your-password');
      passwordInput.type = this.showPassword ? 'text' : 'password';
    },
    resetError(type){
      if(type === 'email'){
          this.emailError = false;
      }
      else this.passError = false;
      this.errorMsg = '';
    },
      async submitReisger() {
          this.onSubmiting = true;

          // Validate
          if(isValidEmail(this.email) === false){
              this.onSubmiting = false;
              this.emailError = true;
              this.errorMsg = this.$t('sign_up.email_required');
              return;
          }
          if (this.password.length < 6) {
              this.onSubmiting = false;
              this.passError = true;
              this.errorMsg = this.$t('sign_up.pass_invalid');
              return;
          }
          if (this.password !== this.repassword) {
              this.onSubmiting = false;
              this.passError = true;
              this.errorMsg = this.$t('sign_up.repass_invalid');
              return;
          }

          // Register
          const resp = await registerAsync(this.email, this.password, this.repassword, this.displayName, this.fbName, this.fbUrl);

          if (resp.success == true){
              this.$router.push('/login');
          } else {
              this.onSubmiting = false;
              this.errorMsg = resp.data;
          }
      }
  }
};
</script>
<style scoped>
@import url('@/assets/style/login.css');
.icon-field .form-control {
  -webkit-padding-start: 1rem;
  padding-inline-start: 1rem;
}
</style>
