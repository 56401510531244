<template>
  <div>
    <form @submit.prevent="submitAsync">
      <div class="center-box--without-bg">
        <div style="display: flex">
          <div class="user-main-info__avatar">
            <i v-if="!userObj.avatar" class="fas fa-user"></i>
            <img v-else :src="backEndUrl + userObj.avatar" />
          </div>
          <h5 style="margin-top: 10px">{{ $t("my_account.title") }}</h5>
        </div>
      </div>

      <!-- License info -->
      <div class="center-box">
        <h6>{{ $t("my_account.account_infor.title") }}</h6>
        <hr />
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="email">Email <span class="cl-red">*</span></label>
              <input
                v-model="userObj.email"
                type="text"
                class="form-control"
                id="email"
                disabled
              />
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group">
              <label for="display_name">{{
                $t("my_account.account_infor.display_name")
              }}</label>
              <input
                v-model="userObj.display_name"
                type="text"
                class="form-control"
                id="display_name"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="support_fb">{{
                $t("my_account.account_infor.facebook")
              }}</label>
              <input
                v-model="supportInfos.facebook"
                v-bind:disabled="supportInfos.facebook != null"
                type="text"
                id="support_fb"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="support_tele">{{
                $t("my_account.account_infor.telegram")
              }}</label>
              <input
                v-model="supportInfos.telegram"
                type="text"
                id="support_tele"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <input
                v-model="isChangePassword"
                type="checkbox"
                id="is_change_password"
              />
              <label for="is_change_password"
                >&nbsp;
                {{ $t("my_account.account_infor.is_change_password") }}</label
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="password">{{
                $t("my_account.account_infor.password")
              }}</label>
              <input
                v-model="userObj.password"
                autocomplete="true"
                type="password"
                id="password"
                class="form-control"
                :disabled="!isChangePassword"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="re-password">{{
                $t("my_account.account_infor.repassword")
              }}</label>
              <input
                v-model="userObj.repassword"
                autocomplete="true"
                type="password"
                id="re-password"
                class="form-control"
                :disabled="!isChangePassword"
              />
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label for="phoneNum">{{
                $t("my_account.account_infor.phone_number")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="phoneNum"
                v-model="userObj.phone_number"
              />
            </div>
          </div>

          <div class="col-md-12">
            <input type="checkbox" id="notInVietNam" v-model="notInVietNam" />
            <label for="notInVietNam" class="form-label"
              >&nbsp;{{ $t("my_account.account_infor.not_in_vi") }}</label
            >
            <br /><br />
          </div>

          <div class="col-md-4" v-if="!notInVietNam">
            <div class="form-group">
              <label for="province"
                >{{ $t("my_account.account_infor.province.label")
                }}<span class="cl-red">*</span></label
              >
              <Dropdown
                v-model="userObj.province"
                filter
                :options="provinceListMap"
                optionLabel="name"
                optionValue="value"
                :placeholder="
                  $t('my_account.account_infor.province.placeholder')
                "
                @change="provinceChanged"
                class="w-full md:w-14rem"
              />
            </div>
          </div>
          <div class="col-md-4" v-if="!notInVietNam">
            <div class="form-group">
              <label for="district"
                >{{ $t("my_account.account_infor.district.label")
                }}<span class="cl-red">*</span></label
              >
              <Dropdown
                v-model="userObj.district"
                filter
                :options="districtListMap"
                optionLabel="name"
                optionValue="value"
                :placeholder="
                  $t('my_account.account_infor.district.placeholder')
                "
                class="w-full md:w-14rem"
                @change="districtChanged"
              />
            </div>
          </div>
          <div class="col-md-4" v-if="!notInVietNam">
            <div class="form-group">
              <label for="ward"
                >{{ $t("my_account.account_infor.ward.label")
                }}<span class="cl-red">*</span></label
              >
              <Dropdown
                v-model="userObj.ward"
                filter
                :options="wardListMap"
                optionLabel="name"
                optionValue="value"
                :placeholder="$t('my_account.account_infor.ward.placeholder')"
                class="w-full md:w-14rem"
                required
              />
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label for="address"
                >{{ $t("my_account.account_infor.address") }}
                <span class="cl-red">*</span></label
              >
              <input
                type="text"
                class="form-control"
                id="address"
                v-model="userObj.addressText"
                required
              />
            </div>
            <div class="form-group">
              <label for="tax">{{
                $t("my_account.account_infor.tax_code")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="tax"
                v-model="userObj.tax_code"
              />
            </div>
          </div>
        </div>

        <br />
      </div>

      <div class="center-box">
        <h6>{{ $t("my_account.affiliate_infor.title") }}</h6>
        <hr />
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="aff_code">{{
                $t("my_account.affiliate_infor.aff_code")
              }}</label>
              <input
                v-model="userObj.affiliate_code"
                type="text"
                class="form-control"
                id="aff_code"
                disabled
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="aff_point">{{
                $t("my_account.affiliate_infor.aff_point")
              }}</label>
              <input
                v-model="userObj.affiliate_point"
                type="text"
                class="form-control"
                id="aff_point"
                disabled
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="payment_bank_id">{{
                $t("my_account.affiliate_infor.payment_bank_id")
              }}</label>
              <input
                v-model="userObj.payment_bank_id"
                type="text"
                class="form-control"
                id="payment_bank_id"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="payment_bank_user_name">{{
                $t("my_account.affiliate_infor.payment_bank_user_name")
              }}</label>
              <input
                v-model="userObj.payment_bank_user_name"
                type="text"
                class="form-control"
                id="payment_bank_user_name"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="payment_bank_name">{{
                $t("my_account.affiliate_infor.bank_name")
              }}</label>
              <Dropdown
                v-model="userObj.payment_bank_name"
                filter
                show-clear
                :options="bankOptions"
                optionLabel="name"
                optionValue="value"
                :placeholder="$t('my_account.affiliate_infor.bank_name_placeholder')"
                class="w-full md:w-14rem"
              />
            </div>
          </div>
        </div>
        <br /><br />
      </div>

      <div class="center-box--without-bg saving-wrapper">
        <button
          type="submit"
          class="btn btn-success"
          :class="{ 'loading-btn': onSubmiting }"
        >
          <div
            class="spinner-border text-light spinner-border-sm"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <span>{{ $t("my_account.update") }}</span>
        </button>
        <router-link to="/dashboard" class="btn btn-danger">{{
          $t("my_account.cancel")
        }}</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import {
  getCurrentUserAsync,
  updateCurrentUserAsync,
} from "@/services/authService";
import { getAddressDataAsync } from "@/services/addressProvider";
import Dropdown from "primevue/dropdown";

export default {
  name: "MyAccountPage",
  components: { Dropdown },
  data() {
    return {
      userObj: {
        province: null,
        district: null,
        ward: null,
        addressText: null,
      },
      supportInfos: {
        facebook: "",
        telegram: "",
      },
      isChangePassword: false,
      backEndUrl: process.env.VUE_APP_BACKEND_URL,
      onSubmiting: false,
      addressData: {},
      provinceList: [],
      districtList: [],
      wardList: [],
      notInVietNam: false,
      bankOptions: [
        { name: "ACB", value: "ACB" },
        { name: "MBBANK", value: "MBBANK" },
        { name: "Vietcombank", value: "Vietcombank" },
        { name: "Agribank", value: "Agribank" },
        { name: "Sacombank", value: "Sacombank" },
        { name: "VPBank", value: "VPBank" },
        { name: "Techcombank", value: "Techcombank" },
        { name: "VIB Bank", value: "VIBBank" },
        { name: "BIDV", value: "BIDV" },
        { name: "TP Bank", value: "TP Bank" },
        { name: "VietinBank", value: "VietinBank" },
        { name: "OCB Bank", value: "OCB Bank" },
        { name: "Lienvietpost Bank", value: "Lienvietpost Bank" },
        { name: "Oceanbank", value: "Oceanbank" },
      ],
    };
  },
  created() {
    document.title = "My account";
  },
  async mounted() {
    this.addressData = await getAddressDataAsync();

    const result = await getCurrentUserAsync();
    if (result.success === true) {
      this.userObj = result.data;
      if (this.userObj.support_info == null) this.userObj.support_info = "{}";
      this.supportInfos = JSON.parse(this.userObj.support_info);
      this.userObj.password = "";
      this.userObj.repassword = "";
    }

    // Get all province
    this.provinceList = Object.keys(this.addressData);
    this.provinceList.sort((a, b) => a.localeCompare(b));

    // Fill address from raw data
    if (
      this.userObj.address != null &&
      this.userObj.address.split("").length > 0
    ) {
      const addressParts = this.userObj.address.split(",");

      if (addressParts.length == 4) {
        this.userObj.addressText = addressParts[0].trim();
        this.userObj.province = addressParts[3].trim();
        this.provinceChanged();

        this.userObj.district = addressParts[2].trim();
        this.districtChanged();

        this.userObj.ward = addressParts[1].trim();
        this.notInVietNam = false;
      } else {
        this.userObj.addressText = this.userObj.address;
        this.notInVietNam = true;
      }
    }
  },
  computed: {
    provinceListMap() {
      return this.provinceList.map((item) => {
        return { name: item, value: item };
      });
    },
    districtListMap() {
      return this.districtList.map((item) => {
        return { name: item, value: item };
      });
    },
    wardListMap() {
      return this.wardList.map((item) => {
        return { name: item, value: item };
      });
    },
  },
  methods: {
    async submitAsync() {
      if (
        this.userObj.password.length > 0 &&
        this.userObj.password.length < 6
      ) {
        this.$toast.error(this.$t("my_account.noti.pass_required"));
        return;
      }

      if (
        this.userObj.password.length > 0 &&
        this.userObj.password != this.userObj.repassword
      ) {
        this.$toast.error(this.$t("my_account.noti.pass_wrong"));
        return;
      }

      if (!this.notInVietNam) {
        if (!this.userObj.province) {
          this.$toast.error(this.$t("my_account.noti.province_required"));
          return;
        }

        if (!this.userObj.district) {
          this.$toast.error(this.$t("my_account.noti.district_required"));
          return;
        }

        if (!this.userObj.ward) {
          this.$toast.error(this.$t("my_account.noti.ward_required"));
          return;
        }
      }
      this.onSubmiting = true;
      this.userObj.support_info = JSON.stringify(this.supportInfos);

      if (this.notInVietNam) this.userObj.address = this.userObj.addressText;
      else
        this.userObj.address =
          this.userObj.addressText.replace(",", "") +
          ", " +
          this.userObj.ward +
          ", " +
          this.userObj.district +
          ", " +
          this.userObj.province;

      const result = await updateCurrentUserAsync(this.userObj);

      if (result.success === true) {
        this.$toast.success(this.$t("my_account.noti.success"));
      } else {
        this.$toast.error(result.message);
      }

      this.onSubmiting = false;
    },

    provinceChanged() {
      this.wardList = [];
      if (this.addressData[this.userObj.province]) {
        this.districtList = Object.keys(
          this.addressData[this.userObj.province]
        );
        this.districtList.sort((a, b) => a.localeCompare(b));
      } else this.districtList = [];
      this.userObj.district = null;
      this.userObj.ward = null;
    },

    districtChanged() {
      if (this.addressData[this.userObj?.province]?.[this.userObj?.district]) {
        this.wardList =
          this.addressData[this.userObj.province][this.userObj.district];
        this.wardList.sort((a, b) => a.localeCompare(b));
      } else this.wardList = [];
      this.userObj.ward = null;
    },
  },
};
</script>

<style scoped>
.user-main-info__avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #d3d3d3;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.user-main-info__avatar i {
  font-size: 28px;
  text-align: center;
  margin-top: 8px;
  color: white;
}

.user-main-info__avatar img {
  width: 100%;
}
hr {
  margin: 1rem 0 !important;
}
</style>
