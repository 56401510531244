<template>
  <div>
    <div class="center-box--without-bg">
      <h5>{{ $t("dashboard_page.title") }}</h5>
    </div>

    <div class="center-box--without-bg">
      <div class="data-table table-pc">
        <table>
          <thead>
            <tr>
              <th>{{ $t("dashboard_page.table.license_purchased") }}</th>
              <th>{{ $t("dashboard_page.table.create_at") }}</th>
              <th>{{ $t("dashboard_page.table.information") }}</th>
              <th>{{ $t("dashboard_page.table.type_account") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ dashboardObj.user.licenses_count }}</td>
              <td>{{ formatDateStr(dashboardObj.user.created_at) }}</td>
              <td>
                {{ formatDateTimeStr(dashboardObj.user.last_login_at) }}
                <span v-if="dashboardObj.user.last_login_ip"
                  >/{{ dashboardObj.user.last_login_ip }}
                </span>
              </td>
              <td>
                <span
                  v-if="dashboardObj.user.licenses_count >= 10"
                  class="badge bg-danger"
                  >VIP</span
                >
                <span
                  v-if="
                    dashboardObj.user.licenses_count >= 1 &&
                    dashboardObj.user.licenses_count < 10
                  "
                  class="badge bg-warning"
                  >Normal</span
                >
                <span
                  v-if="dashboardObj.user.licenses_count == 0"
                  class="badge bg-success"
                  >Newbie</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="center-box table-mobile">
        <table>
          <tr>
            <td class="tb-header">
              {{ $t("dashboard_page.table.license_purchased") }}:
            </td>
            <td>
              {{ dashboardObj.user.licenses_count }}
            </td>
          </tr>
          <tr>
            <td class="tb-header">
              {{ $t("dashboard_page.table.create_at") }}:
            </td>
            <td>
              {{ formatDateStr(dashboardObj.user.created_at) }}
            </td>
          </tr>
          <tr>
            <td class="tb-header">
              {{ $t("dashboard_page.table.information") }}:
            </td>
            <td>
              {{ formatDateTimeStr(dashboardObj.user.last_login_at) }}
              <span v-if="dashboardObj.user.last_login_ip"
                >/{{ dashboardObj.user.last_login_ip }}
              </span>
            </td>
          </tr>
          <tr>
            <td class="tb-header">
              {{ $t("dashboard_page.table.type_account") }}:
            </td>
            <td>
              <span
                v-if="dashboardObj.user.licenses_count >= 10"
                class="badge bg-danger"
                >VIP</span
              >
              <span
                v-if="
                  dashboardObj.user.licenses_count >= 1 &&
                  dashboardObj.user.licenses_count < 10
                "
                class="badge bg-warning"
                >Normal</span
              >
              <span
                v-if="dashboardObj.user.licenses_count == 0"
                class="badge bg-success"
                >Newbie</span
              >
            </td>
          </tr>
        </table>
      </div>
      <div class="social-containter">
        <a
          href="https://facebook.com/giaiphapmmodotnet"
          target="_blank"
          title="Facebook"
          ><i class="fab fa-facebook social social-fb"></i
        ></a>
        &nbsp;&nbsp;&nbsp;
        <a
          href="https://www.youtube.com/@giaiphapmmodotnet/playlists"
          target="_blank"
          title="Youtube"
          ><i class="fab fa-youtube social social-ytb"></i
        ></a>
        &nbsp;&nbsp;&nbsp;
        <a href="https://giaiphapmmo.vn/" target="_blank" title="Website"
          ><i class="fas fa-globe-asia social social-web"></i
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import { getDashboardData } from "@/services/customerService";
import { formatDateString, formatDateTimeString } from "@/helpers/strHelper";
export default {
  name: "DashboardPage",
  data() {
    return {
      dashboardObj: {
        user: {},
        aff_data: [],
      },
    };
  },
  updated() {
    document.title = this.$t("dashboard_page.document_title");
  },

  async mounted() {
    const result = await getDashboardData();

    if (result.success === true) {
      this.dashboardObj = result.data;
    }
  },

  methods: {
    copyAffCode() {
      navigator.clipboard.writeText(this.dashboardObj.user.affiliate_code);
      this.$toast.success(this.$t("affiliate_page.noti.coppied"));
    },
    formatDateStr(dateStr) {
      return dateStr ? formatDateString(dateStr) : "";
    },
    formatDateTimeStr(dateStr) {
      return dateStr ? formatDateTimeString(dateStr) : "";
    },
  },
};
</script>

<style scoped>
.dashboard-container {
  margin-top: 20px;
  margin-bottom: 25px;
}

td {
  padding-right: 10px;
  padding-bottom: 7px;
}

.social-containter {
  padding-left: 5px;
}

.social {
  font-size: 18px;
  color: gray;
}
.badge {
  padding: 10px 15px !important;
  border-radius: 50px !important;
}
.bg-danger {
  background-color: var(--danger-200) !important;
  color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}
.bg-warning {
  background-color: var(--warning-100) !important;
  color: var(--warning-main) !important;
}
.bg-success {
  background-color: rgba(68, 179, 105, 0.149) !important;
  color: var(--success-main) !important;
}
.tb-header {
  font-weight: bold;
}
.table-pc {
  display: block;
}

.table-mobile {
  display: none;
}

@media (max-width: 960px) {
  .table-pc {
    display: none;
  }

  .table-mobile {
    display: block;
  }
}
</style>
