import { httpGetAsync, httpPostAsync} from "./requestService";

export const getCurrentUserAsync = async () => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/auth/get-current-user`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const changePasswordAsync = async (password) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/auth/new-password?password=${password}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const getProjectsAsync = async (page, perPage, search, orderBy, orderByType) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/get-projects?page=${page}&per_page=${perPage}&search=${search}&order_by=${orderBy}&order_by_type=${orderByType}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const getProjectAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/get-project/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const getCanBuyProjectsAsync = async () => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/get-can-buy-projects`;
    const resp = await httpGetAsync(url);

    return resp.data;
}


export const createOrderAsync = async (orderObj) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/create-order`;
    const postData = JSON.stringify(orderObj);
    const resp = await httpPostAsync(url, postData);

    return resp.data;
}

export const getOrderDetailsAsync = async (tranferContent) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/get-order-details?transfer_content=${tranferContent}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const getTransferInfoAsync = async (orderId) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/get-transfer-info/${orderId}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const checkTransferedStatusAsync = async (orderId) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/check-transfered-status/${orderId}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const getLicensesAsync = async () => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/get-licenses`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const getSubLicensesAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/get-sub-licenses/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const createSubLicensesAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/create-sub-licenses/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const changeSubLicenseAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/change-sub-license/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const deleteAllSubLicenseAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/delete-sub-license/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}


export const changeLicenseAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/change-license/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const resetLicenseAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/reset-license/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const getDashboardData = async (page) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/get-dashboard-data-2?page=`+page;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const getAffiliateInfoAsync = async (affCode) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/get-affiliate-info/${affCode}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const getAffiliateOrdersAsync = async () => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/get-affiliate-orders`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const getAffiliateOrderDetailsAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/get-affiliate-order/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const createAffiliateOrderAsync = async (withdrawPoint) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/customer/create-affiliate-order`;
    const postObj = {withdraw_point: withdrawPoint};
    const resp = await httpPostAsync(url, JSON.stringify(postObj));

    return resp.data;
}