<template>
  <div class="center-box--without-bg">
    <h5>
      {{ $t("affiliate_withdraw.title") }} ({{ userObj.affiliate_point }} point)
    </h5>
  </div>
  <div class="center-box">
    <p style="color: #999999">{{ $t("affiliate_withdraw.desc") }}</p>

    <div class="flex mt-4">
      <input
        v-model="withDrawPoint"
        type="number"
        class="form-control affiliate-input"
      />
      <button class="btn btn-success ml-2" @click="sendWithDrawCommand">
        {{ $t("affiliate_withdraw.btn_withdraw") }}
      </button>
    </div>
    <br />
    <div class="bank-container">
      <label>{{ $t("affiliate_withdraw.account_withdraw") }}</label>
      <br />
      <b
        v-if="
          userObj.payment_bank_id ||
          userObj.payment_bank_name ||
          userObj.payment_bank_user_name
        "
        >{{ userObj.payment_bank_id }} / {{ userObj.payment_bank_name }} -
        {{ userObj.payment_bank_user_name }}</b
      >
      <span style="color: #999999; font-style: italic" v-else>{{
        $t("affiliate_withdraw.bank_info")
      }}</span>
    </div>
    <p>
      <i class="fas fa-exclamation-triangle" style="color: #e84b00"></i>
      <span v-html="$t('affiliate_withdraw.infor_first')"></span> <br /><br />
      <i class="fas fa-exclamation-triangle" style="color: #e84b00"></i>
      <span v-html="$t('affiliate_withdraw.info_second')"></span>
    </p>
  </div>

  <div class="center-box--without-bg">
    <div class="data-table">
      <table>
        <thead>
          <tr>
            <th>{{ $t("affiliate_withdraw.table.time") }}</th>
            <th>{{ $t("affiliate_withdraw.table.withdraw_point") }}</th>
            <th>{{ $t("affiliate_withdraw.table.status") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr class="loading-row" v-show="inLoading">
            <td colspan="4">
              <div class="loading-spinner"></div>
            </td>
          </tr>
          <tr class="no-data-row" v-if="affOrderObjs.length == 0">
            <td colspan="4">{{ $t("common.no_data") }}</td>
          </tr>
          <tr v-for="item in affOrderObjs" v-bind:key="item.id">
            <td>
              {{ formatDateTimeStr(item.created_at) }}
            </td>
            <td>
              {{ item.withdraw_point }}
            </td>
            <td>
              <span :class="item.status">{{ item.status }}</span>
            </td>
            <td>
              <button  class="btn btn-secondary" @click="goAffiliateDetail(item.id)">{{ $t("affiliate_withdraw.table.detail") }}</button>
              <!-- <router-link
                :to="{ name: 'affiliate-details', params: { id: item.id } }"
                class="btn btn-secondary"
                >{{ $t("affiliate_withdraw.table.detail") }}</router-link
              > -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <ConfirmPasswordBox
    v-if="isShowConfirmPasswordBox"
    ref="confirmPasswordBox"
    @confirmEvent="confirmPasswordDoneEvent"
  ></ConfirmPasswordBox>
</template>

<script>
import { formatDateTimeString } from "@/helpers/strHelper";
import {
  createAffiliateOrderAsync,
  getAffiliateOrdersAsync,
} from "@/services/customerService";
import { getCurrentUserAsync } from "@/services/authService";
import ConfirmPasswordBox from "@/components/ConfirmPasswordBox.vue";

export default {
  name: "AffilateHistoryPage",
  components: {
    ConfirmPasswordBox,
  },
  data() {
    return {
      withDrawPoint: 0,
      affOrderObjs: [],
      userObj: {},
      inLoading: false,
      isShowConfirmPasswordBox: false,
    };
  },
  created() {
    document.title = "Affiliate withdraw| User site";
  },
  async mounted() {
    this.inLoading = true;
    const result = await getAffiliateOrdersAsync();

    if (result.success === true) {
      this.affOrderObjs = result.data;
    }
    const userResult = await getCurrentUserAsync();
    if (userResult.success === true) {
      this.userObj = userResult.data;
    }

    this.inLoading = false;
  },

  methods: {
    async sendWithDrawCommand() {
      if (this.withDrawPoint < 500) {
        this.$toast.error(
          this.$t("affiliate_withdraw.noti.send_withdraw.error1")
        );
        return;
      }

      if (
        this.userObj.payment_bank_id === null ||
        this.userObj.payment_bank_name === null ||
        this.userObj.payment_bank_user_name === null
      ) {
        this.$toast.error(
          this.$t("affiliate_withdraw.noti.send_withdraw.error2")
        );
        return;
      }

      if (this.withDrawPoint % 10 != 0) {
        this.$toast.error(
          this.$t("affiliate_withdraw.noti.send_withdraw.error3")
        );
        return;
      }
      this.isShowConfirmPasswordBox = true;
      this.$nextTick(() => {
        this.$refs.confirmPasswordBox.showModal();
      });
    },

    async confirmPasswordDoneEvent(eventResult) {
      this.isShowConfirmPasswordBox = false;
      if (eventResult === true) {
        const result = await createAffiliateOrderAsync(this.withDrawPoint);
        if (result.success === true) {
          this.affOrderObjs.unshift(result.data);
          this.$toast.success("Tạo lệnh rút tiền thành công");
        } else {
          this.$toast.error(result.message);
        }
      } else {
        this.$toast.error("Mật khẩu nhập lại không chính xác");
      }
    },
    goAffiliateDetail(id) {      
       this.$router.push({ name: "affiliate-detail", query: { id: id } });

    },
    formatDateTimeStr(dateString) {
      return formatDateTimeString(dateString);
    },
  },
};
</script>

<style scoped>
.affiliate-input {
  max-width: 100px !important;
}

.SUCCESS {
  color: #00d200;
}

.ERRORn {
  color: red;
}

.bank-container {
  background: #f3f3f3;
  border-radius: 5px;
  padding: 15px 10px;
  margin-bottom: 15px;
}

.bank-container b {
  color: #4b4b4b;
}
</style>
