<template>
    <div>
        Đang tải...
    </div>
</template>

<script>
export default {
    name: 'HomePage',
    created() {
        this.$router.push({name: 'dashboard'});
    }
}
</script>