<template>
  <div>
    <h5>Affiliate (<span class="aff-point-label">{{ dashboardObj.user.affiliate_point }}</span> point)</h5>
    <span v-if="dashboardObj.user.affiliate_verified == 1" class="badge bg-success">{{$t('affiliate_page.account_status.verified')}}</span>
    <span v-if="dashboardObj.user.affiliate_verified == 0" class="badge bg-danger">{{$t('affiliate_page.account_status.unverified')}}</span>
    &nbsp;&nbsp;&nbsp;
    <small style="color: #949494; font-size: .875em" v-if="dashboardObj.user.affiliate_verified == 0" v-html="$t('affiliate_page.desc')"></small>

    <div class="dashboard-container">
      <span>{{$t('affiliate_page.aff_code')}}</span>
      <div class="input-group">
        <input type="text" class="affiliate-input form-control" :value="dashboardObj.user.affiliate_code" disabled>
        <i class="far fa-copy mt-2" title="Copy" @click="copyAffCode"></i>
      </div>
      <div>
        <router-link :to="{ name: 'affiliate-withdraw' }" class="btn btn-success">{{$t('affiliate_page.withdraw')}}</router-link>
        &nbsp;
        <router-link :to="{ name: 'affiliate-withdraw' }" class="btn btn-secondary">{{$t('affiliate_page.withdraw_history')}}</router-link>
        <br><br>
      </div>
      <span>{{$t('affiliate_page.aff_history')}}</span><br /><br />
      <div class="data-table">
        <table>
          <thead>
            <tr>
              <th>{{$t('affiliate_page.table.time')}}</th>
              <th>{{$t('affiliate_page.table.customer')}}</th>
              <th>{{$t('affiliate_page.table.software')}}</th>
              <th>{{$t('affiliate_page.table.aff_point')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="this.dashboardObj.aff_data.data.length == 0" >
              <td colspan="4" style="text-align: center;">{{$t('common.no_data')}}</td>
            </tr>
            <tr v-for="item in dashboardObj.aff_data.data" v-bind:key="item.id">
              <td>{{ formatDateTimeStr(item.created_at) }}</td>
              <td>{{ item.customer.display_name }}</td>
              <td>{{ item.project.name }}</td>
              <td>{{ item.affiliate_point }}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

    <div style="text-align: center;" v-if="dashboardObj.aff_data.data.length > 0">
        <span style="color: #0080ff; cursor: pointer; border-radius: 10px; padding: 8px 15px; background: #E1F0FF;" @click="loadMorePageAsync($event)">{{$t('common.load_more')}}</span>
    </div>
  </div>
</template>

<script>
import { getDashboardData } from '@/services/customerService';
import { formatDateString, formatDateTimeString } from '@/helpers/strHelper';
export default {
  name: 'DashboardPage',
  data() {
    return {
      dashboardObj: {
        user: {},
        aff_data: {
          data: []
        }
      },
      affPage: 1
    }
  },
  created() {
    document.title = 'Affiliate | User site';
  },

  async mounted() {
    const result = await getDashboardData(this.affPage);

    if (result.success == true) {
      this.dashboardObj = result.data;
    }
  },

  methods: {
    async loadMorePageAsync(event){
      event.target.innerText = this.$t('common.loading')+'...';
      this.affPage++;
      const result = await getDashboardData(this.affPage);
      event.target.innerText = this.$t('common.load_more');
      if (result.success == true){
        const data = result.data.aff_data.data;
        this.dashboardObj.aff_data.data.push(...data);
      }
    },

    copyAffCode() {
      navigator.clipboard.writeText(this.dashboardObj.user.affiliate_code);
      this.$toast.success(this.$t('affiliate_page.noti.coppied'));
    },
    formatDateStr(dateStr) {
      return formatDateString(dateStr);
    },
    formatDateTimeStr(dateStr) {
      return formatDateTimeString(dateStr);
    }
  }
}
</script>

<style scoped>
.dashboard-container {
  margin-top: 20px;
  margin-bottom: 25px;
}

td {
  padding-right: 10px;
  padding-bottom: 7px;
}

.input-group {
  position: relative;
  width: 200px;
}

.input-group i {
  cursor: pointer;
  color: gray !important;
  font-size: 18px;
  position: absolute;
  top: 13px;
  right: 10px;
}


.affiliate-input {
  margin-top: 5px;
  margin-bottom: 20px;
}

.aff-point-label {
  color: orange;
  font-weight: bold;
}
.badge{
    padding: 10px 15px !important;
    border-radius: 50px !important;
}
</style>