<template>
  <component :is="layout"></component>
</template>

<script>
import AuthLayout from './layouts/AuthLayout';
import UserLayout from './layouts/UserLayout';
import { markRaw } from 'vue';

export default {
  name: 'App',
  components: {
    AuthLayout,
    UserLayout
  },
  data() {
    return  {
      layout: null
    }
  },
  watch: {
    $route(to) {
      if (to.meta.layout !== undefined) {
        this.layout = markRaw(to.meta.layout); // set layout by route; use marRaw to fix vue warning
      } else {
        this.layout = markRaw(UserLayout); // default
      }
    },
  },
}
</script>