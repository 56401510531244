<template>
  <div>
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
    <LeftMenu></LeftMenu>
    <el-drawer
      class="side-bar-drawer"
      :direction="'ltr'"
      v-model="displaySidebarMobile"
    >
      <LeftMenuMobile @toggle="toggleMenu" />
    </el-drawer>
    <main class="main-content">
      <div class="navbar-header">
        <div class="row align-items-center justify-content-between">
          <div class="col-auto">
            <button type="button" class="sidebar-toggle" @click="toggleSidebar">
              <i class="fa-solid fa-bars"></i>
            </button>
          </div>
          <div class="col-auto">
            <div class="d-flex flex-wrap align-items-center gap-4">
              <el-dropdown placement="bottom" trigger="click">
                <img
                  class
                  :src="flag"
                  alt="Header Language"
                  style="height: 1.3rem"
                />
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      v-for="(entry, i) in languages"
                      :key="`Lang${i}`"
                      class="notify-item"
                      :value="entry"
                      :link-class="{
                        active: entry.language === current_language,
                      }"
                      @click="
                        setLanguage(entry.language, entry.title, entry.flag)
                      "
                    >
                      <img
                        :src="`${entry.flag}`"
                        alt="user-image"
                        class="mr-1"
                        style="height: 1rem"
                      />
                      <span class="align-middle">{{ entry.title }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              <a
                type="button"
                class="navbar-menu-button"
                @click="toogleUserMenuOption"
                ><img
                  src="../assets/images/no-user.png"
                  alt="Avatar"
                  :v-tooltip="'Tài khoản của tôi'"
              /></a>
              <ul
                ref="userMenuOption"
                v-show="isShowUserMenuOption"
                class="user-option-container__menu"
              >
                <li>
                  <router-link
                    :to="{ name: 'my-account' }"
                    @click="closeUserMenuOption"
                    ><i class="fas fa-info-circle"></i>{{ $t("context_menu.account_infor") }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    :to="{ name: 'my-account' }"
                    @click="closeUserMenuOption"
                    ><i class="fas fa-user-lock"></i>{{ $t("context_menu.security") }}</router-link
                  >
                </li>
                <li>
                  <a href="#" @click="userLogoutAsync($event)"
                    ><i class="fas fa-sign-out-alt"></i>{{ $t("context_menu.logout") }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="main-content-body">
        <router-view></router-view>
      </div>

      <footer class="footer-layout">
        <p>© 2024 GPM Software Solutions Limited Co. All Rights Reserved.</p>
      </footer>
    </main>
  </div>
</template>

<script>
import { logoutAsync } from "@/services/authService";
import LeftMenu from "../components/LeftMenu.vue";
import LeftMenuMobile from "../components/LeftMenuMobile.vue";

export default {
  name: "UserLayout",
  components: {
    LeftMenu,
    LeftMenuMobile,
  },
  data() {
    return {
      isLoading: true,
      isShowUserMenuOption: false,
      isUserMenuLoaded: false,
      displaySidebarMobile: false,
      currentUserLastName: this.$store.getters.getCurrentUserLastName,
      languages: [
        {
          flag: require("@/assets/images/flags/vn.svg"),
          language: "vi",
          title: "Tiếng Việt",
        },
        {
          flag: require("@/assets/images/flags/sh.svg"),
          language: "en",
          title: "English",
        },
      ],
      current_language: this.$i18n.locale,
      text: null,
      flag: null,
      value: null,
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutsidePopup);
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
    const currentLanguage = localStorage.getItem("lang");
    if (currentLanguage) {
      this.setLanguage(
        currentLanguage,
        this.languages.find((lang) => lang?.language === currentLanguage).title,
        this.languages.find((lang) => lang?.language === currentLanguage).flag
      );
    } else {
      this.value = this.languages.find((x) => x.language === this.$i18n.locale);
      this.text = this.value.title;
      this.flag = this.value.flag;
    }
  },
  methods: {
    async userLogoutAsync(e) {
      this.$swal.fire({
        title: this.$t('common.notify.warring'),
        text: this.$t('context_menu.logout_confirm'),
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: this.$t('common.notify.reject'),
        confirmButtonText: this.$t('common.notify.confirm'),
      }).then(async (result)=>{
        if (result.isConfirmed) {
          await logoutAsync();
          this.$router.push("login");
          e.preventDefault();
        }
      })

      
    },
    toogleUserMenuOption() {
      this.isShowUserMenuOption = !this.isShowUserMenuOption;

      if (this.isShowUserMenuOption) {
        setTimeout(() => {
          this.isUserMenuLoaded = true;
        }, 100);
      } else this.isUserMenuLoaded = false;
    },
    closeUserMenuOption() {
      this.isShowUserMenuOption = false;
      this.isUserMenuLoaded = false;
    },
    handleClickOutsidePopup(event) {
      if (!this.$refs.userMenuOption) return;

      const popupEl = this.$refs.userMenuOption;

      if (
        this.isUserMenuLoaded &&
        this.isShowUserMenuOption &&
        !popupEl.contains(event.target)
      ) {
        this.isShowUserMenuOption = false;
        this.isUserMenuLoaded = false;
      }
    },
    toggleSidebar() {
      if (window.innerWidth >= 767) {
        this.displaySidebarMobile = false
        const elementEffectList = [
          ".sidebar-menu",
          ".sidebar-logo",
          ".main-content",
          "footer",
        ];
        elementEffectList.forEach((selector) => {
          document.querySelectorAll(selector).forEach((element) => {
            element.classList.toggle("collapsed");
          });
        });
      } else this.displaySidebarMobile = true;
    },
    setLanguage(locale, country, flag) {
      this.$i18n.locale = locale;
      this.current_language = locale;
      this.text = country;
      this.flag = flag;
      localStorage.setItem("lang", locale);
    },
    toggleMenu() {
      this.displaySidebarMobile = false;
    },
  },
};
</script>

<style scoped>
.top-wrapper {
  width: 100%;
  height: 55px;
  padding: 15px;
  background: white;
  border-bottom: 1px solid #e3e3e3;
  display: flex;
  justify-content: space-between;
}

.toogleLeftMenu-btn {
  cursor: pointer;
}

.user-option-container {
  position: relative;
  cursor: pointer;
}

.user-option-container__menu {
  position: absolute;
  top: 45px;
  right: 0px;
  list-style-type: none;
  background: white;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin: 0;
  padding: 15px 0;
  width: 150px;
}

.user-option-container__menu li {
  margin: 0;
  padding: 0;
}

.user-option-container__menu li a {
  display: block;
  padding: 5px 15px;
  color: #404040;
}

.user-option-container__menu li a:hover {
  color: #787878;
}

.user-option-container__menu li a i {
  margin-right: 5px;
}

.content-wrapper {
  z-index: 1000;
  min-height: 105vh;
  /* Scrollbar alway visiable */
}

.systemHealth {
  color: #00ac2c;
}

/* Loading */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
/* main content */
.main-content {
  margin-left: var(--sidebar-width);
  transition: all 0.3s;
  overflow-y: hidden;
}

.main-content.collapsed {
  margin-left: var(--sidebar-collapsed-width);
}

.navbar-header {
  height: 4rem;
  border-bottom: 1px solid var(--border-end-color);
  background: white;
  padding: 15px 30px 15px 20px;
  position: sticky;
  top: 0;
  z-index: 99;
}

.sidebar-toggle {
  border: none;
  background: white;
  font-size: 1.5rem;
}

.navbar-menu-button {
  height: 2.5rem;
  width: 2.5rem;
  background: #ebecef;
  border-radius: 50%;
  border: none;
  overflow: hidden;
  padding: 0;
  display: block;
  text-align: center;
}

.navbar-menu-button i {
  line-height: 2.5rem;
  text-decoration: none;
  color: #111827;
}

.navbar-menu-button .avatar {
  height: 2.5rem;
  width: 2.5rem;
  display: block;
}

.navbar-menu-button.flash {
  animation: flash 0.6s linear infinite alternate;
}

@keyframes flash {
  0% {
    background: #ebecef;
  }
  100% {
    background: #eaea00;
  }
}

.main-content-body {
  padding: 1.5rem;
  overflow: auto;
  height: calc(100vh - 104px);
}

.content-header {
  color: #111827;
  font-size: clamp(1.125rem, 1.005rem + 0.6vw, 1.5rem);
}
/* end main content  */
/* Footer */
.footer-layout {
  background: white;
  position: fixed;
  bottom: 0;
  left: var(--sidebar-width);
  width: calc(100% - var(--sidebar-width));
  padding: 15px 20px;
  border-top: 1px solid var(--border-end-color);
  font-size: 0.9rem;
  transition: all 0.3s;
  z-index: 999;
}

.footer-layout.collapsed {
  left: var(--sidebar-collapsed-width);
  width: calc(100% - var(--sidebar-collapsed-width));
}

.footer-layout p {
  margin: 0 !important;
}
::v-deep .side-bar-drawer .el-drawer__header {
  margin-bottom: 0 !important;
}
</style>
