import axios from "axios";
import Cookies from 'js-cookie';
import { httpGetAsync, httpPostAsync, TOKEN_NAME} from "./requestService";
import store from '@/store';

// Login account
// Return a object like: {success: true, message: "Thành công"}
export const loginAsync = async (email, password, loginToken = '') => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/auth/login?email=${email}&password=${password}&login_token=${loginToken}`;
    const resp = await httpPostAsync(url);

    if (resp?.data?.success == true) {
        // localStorage.setItem(TOKEN_NAME, resp.data.data);
        Cookies.set(TOKEN_NAME, resp.data.data, { expires: 7, secure: true, sameSite: 'Strict' });

    }
    
    return resp.data;
}

export const registerAsync = async (email, password, repassword, displayName, fbName, fbUrl) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/auth/customer-register`;
    const obj = {
        email: email,
        password: password,
        re_password: repassword,
        display_name: displayName,
        support_info: JSON.stringify({
            facebook_url: fbUrl,
            facebook_name: fbName
        })
    };

    return (await axios.post(url, obj)).data;
}

export const verifyTokenAsync = async () =>
{
    const token = Cookies.get(TOKEN_NAME)

    if (token) {
        const authCache = localStorage.getItem('AUTH_CACHE');
        if (authCache){
            const authCacheObj = JSON.parse(authCache);
            store.commit('setCurrentUserDisplayName', authCacheObj.data.data.user.display_name);

            return authCacheObj;
        }

        // New request
        const url = `${process.env.VUE_APP_BACKEND_API_URL}/auth/check-token?route_name=`;
        const resp = await httpGetAsync(url);

        // Set access_routes to store
        if (resp.data.success === true){
            store.commit('setCurrentUserDisplayName', resp.data.data.data.user.display_name);

            // Save to cache
            localStorage.setItem('AUTH_CACHE', JSON.stringify(resp.data));
        }

        return resp.data;
    }
    
    return {success: false};
}

export const getCurrentUserAsync = async () => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/auth/get-current-user`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const updateCurrentUserAsync = async (userObj) => {
    const postData = JSON.stringify(userObj);
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/auth/update-current-user`;
    const resp = await httpPostAsync(url, postData);

    return resp.data;
}

export const logoutAsync = async () => {
    Cookies.remove(TOKEN_NAME);
    localStorage.removeItem('AUTH_CACHE');
    
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/auth/logout`;
    await httpGetAsync(url);
}

export const forgotPasswordAsync = async (email) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/auth/send-password-reset-link?email=${email}`;
    const resp = await httpGetAsync(url);
    
    return resp.data;
}

export const resetPasswordAsync = async (email, newPassword, token) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/auth/reset-password?token=${token}&password=${newPassword}&email=${email}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}