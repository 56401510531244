<template>
  <aside class="sidebar-menu">
    <span class="sidebar-logo cursor-pointer">
      <img src="../assets/logo-gpm.svg" alt="" class="logo logo-notcollapsed"/>
      <img src="../assets/logo-gpm-min.svg" alt="" class="logo logo-collapsed"/>
    </span>
    <ul>
      <li v-for="(item, index) in MENU_CONFIG" :key="index" class="menu-item cursor-pointer" :class="{ active: $route.name === item.link }" @click="toggleMenu(item)">
        <router-link :to="{name: item.link}"> 
          <i class="menu-icon" :class="item.icon"></i>
          <span>{{ $t(item.label) }}</span>
        </router-link>
      </li>
    </ul>
  </aside>
</template>

<script>
import { MENU_CONFIG } from "@/constants/menu";
export default {
  name: "LeftMenu",
  data() {
    return {
      accessRouteNames: [],
      MENU_CONFIG,
    };
  },
  methods: {
    canAccessMenu(routeName) {
      if (this.$store.getters.getSystemRole == "ADMIN") return true;

      return this.accessRouteNames.includes(routeName);
    },
    openApiDocUrl() {
      const url = `${process.env.VUE_APP_BACKEND_URL}/api/back-end-doc`;
      window.open(url, "_blank");
    },
    toggleMenu(item) {
        this.$router.push(item?.link);
        this.$emit('toggle');
      },
  },
};
</script>

<style scoped>
@import url('@/assets/style/side-bar.scss');
</style>
