import { createStore } from 'vuex';

const store = createStore({
    state () {
        return {
            displayName: ''
        }
    },
    mutations: {
        setCurrentUserDisplayName(state, displayName) {
            state.displayName = displayName;
        }
    },
    getters: {
        getCurrentUserLastName(state){
            const arr = state.displayName.split(' ');
            return arr[arr.length-1]; 
        }
    }
})

export default store;