<template>
  <div class="center-box support-container">
    <h5>{{$t('support_page.title')}}</h5>
    <br>
    <p>
      <i class="fas fa-check"></i> {{$t('support_page.content_first')}}<br>
      <i class="fas fa-check"></i> {{$t('support_page.content_second')}}<br>
    </p>

      <a href="https://facebook.com/giaiphapmmodotnet" target="_blank" class="btn btn-blue mt-8">
        <i class="fab fa-facebook-messenger" style="color: white; margin-right: 5px;"></i> {{$t('support_page.contact')}}
    </a>
  </div>
</template>

<script>
export default {
  name: 'SupportPage',
  data() {
    return {
      title: this.$t('support_page.document_title')
    }
  },
  updated() {
    document.title = this.title;
  }
}
</script>

<style scoped>
.support-container {
  max-width: 1000px;
  background: #D7EBFF;
  color: #0080c0;
}

.support-container p {
  line-height: 25px;
}
</style>