import Cookies from 'js-cookie';
const axios = require('axios').default;

const TOKEN_NAME = 'token';

const httpGetAsync = async (url) => {
    const token =  Cookies.get(TOKEN_NAME);

    try {
        const resp = await axios.get(url, {
            headers:{
                Authorization : `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        return resp;
    } catch (error){
        if (error.message.includes('401')){
            Cookies.remove(TOKEN_NAME);
            localStorage.removeItem('AUTH_CACHE');
            document.location = '/login';
        }
        return null;
    }
}

const httpPostAsync = async (url, data) => {
    const token = Cookies.get(TOKEN_NAME)

    try {
        const resp = await axios.post(url, data, {
            headers:{
                Authorization : `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
    
        return resp;
    } catch (error){
        if (error.message.includes('401')){
            Cookies.removeItem(TOKEN_NAME);
            localStorage.removeItem('AUTH_CACHE');
            document.location = '/login';
        }
        return null;
    }
}

export {httpGetAsync, httpPostAsync, TOKEN_NAME};