<template>
    <div class="login-container">
        <form @submit.prevent="submit">
            <div class="login-container__logo">
                <img src="../../assets/login-icon.png">
            </div>
            <h1>Reset password</h1>
            <div v-if="message">
                <br>
                <span :style="{color: messageColor}">{{ message }}</span>
            </div>
            <br>

            <AwesomeInput v-model="password" label="Password" id="password" type="password"></AwesomeInput>
            <AwesomeInput v-model="repassword" label="Re enter password" id="repassword" type="password"></AwesomeInput>
            <br/>
            <button type="submit" class="btn btn-blue form-control" :class="{'loading-btn': onSubmiting}">
                <div class="spinner-border text-light spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <span>Reset password</span>
            </button>
            
        </form>

    </div>
</template>

<script>
import AwesomeInput from '@/components/AwesomeInput.vue';
import {resetPasswordAsync} from '@/services/authService';

export default {
    name: 'PasswordRecoverPage',
    components: {
        AwesomeInput
    },
    data(){
        return {
            title: 'Reset password | User site',
            email: '',
            password: '',
            repassword: '',
            token: '',
            onSubmiting: false,
            message: '',
            messageColor: '#0080C0'
        }
    },
    created() {
        document.title = this.title;
        this.token = this.$route.query.token;
        this.email = this.$route.query.email;
    },
    methods: {
        async submit() {
            this.messageColor = 'red';

            if (this.password.length < 6){
                this.message = 'Password must be at least 6 characters';
                this.onSubmiting = false;
                return;
            }

            if (this.password != this.repassword){
                this.message = 'Re-enter password does not match';
                this.onSubmiting = false;
                return;
            }

            this.onSubmiting = true;
            const resp = await resetPasswordAsync(this.email, this.password, this.token);
            this.message = resp.message;

            if (resp.success == true){
                this.messageColor = '#0080C0';
                const msg = this.message;
                
                for (let i = 5; i >= 0; i--){
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    this.message = msg + `. Auto redirect to login page at ${i} seconds`;
                }

                this.$router.push(this.$router.resolve({name: 'login'}));
            }
            else
                this.messageColor = 'red';

            this.onSubmiting = false;
        }
    }
};
</script>

<style scoped>
h1 {
    font-size: 26px;
    margin-bottom: 10px;
    text-align: center;
}

input, button {
    margin-bottom: 15px;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    opacity: 0.7;
}

.login-container__logo {
    width: 80px;
    margin: 10px auto;
}
.login-container__logo img {
    max-width: 100%;
}
</style>
