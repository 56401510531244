<template>
  <div class="center-box--without-bg">
    <h5>{{ $t("transfer_page.title")}}</h5>
  </div>
  <div class="center-box">
    <div class="warning-container">
      <div><i class="fas fa-exclamation-triangle" style="color: #e84b00"></i> <span v-html="$t('transfer_page.warring_first')"></span></div>
    </div>
    <hr>
    <br>
    <div class="row">
      <div class="col-md-12 col-xl-4">
        <label>{{ $t("transfer_page.software_name")}}</label><br>
        <b>{{ this.appName }}</b>
        <ul class="module-container">
          <li v-for="item in modules" v-bind:key="item">
            <span>{{ item }}</span>
          </li>
        </ul>

        <label>>{{ $t("transfer_page.price")}}</label><br>
        <b class="cl-red">{{ formatVnPrice(orderObj.actual_price)}}</b>
        <br><br>

        <label>{{ $t("transfer_page.transfer_content")}}</label><br>
        <b class="cl-red">{{ orderObj.transfer_content }}</b>
        <br><br>

        <label>>{{ $t("transfer_page.bank_account_number")}}</label><br>
        <b class="cl-red">{{ bankAccountNumber }}</b><br>
        <b>{{ bankName }} - {{ bankAccountName }}</b>
        <br><br>
      </div>
      <div class="col-md-12 col-xl-8 row">
        <img
          :src="transferImage"
          class="col-xl-8 col-sm-12 col-12"
          >
      </div>
      <div class="col-md-12">
        <br>
        <i v-html="$t('transfer_page.note')"></i>
      </div>
    </div>
  </div>
</template>

<script>
import * as db from '@/services/customerService';
import { formatPrice } from '@/helpers/strHelper';

export default {
  name: 'TransferPage',
  data() {
    return {
      orderObj: {},
      appName: '',
      modules: [],
      transferImage: '/assets/img/loading_img.gif',
      bankName: 'MB Bank',
      bankAccountNumber: '',
      bankAccountName: ''
    }
  },
  updated() {
    document.title =this.$t('transfer_page.document_title');
  },
  async mounted() {
    const transferContent = this.$route.query.order;
    const result = await db.getOrderDetailsAsync(transferContent);

    if (result.success == true){
      this.orderObj = result.data;
      this.modules = JSON.parse(this.orderObj.project_module_names);
      this.appName = this.orderObj.project.name + ' / '+this.orderObj.project_package.name;

      // Transfer info
      const transferGetResult = await db.getTransferInfoAsync(this.orderObj.id);

      if (transferGetResult.success == true){
        this.orderObj.transfer_content = transferGetResult.data.transfer_content;

        this.transferImage = transferGetResult.data.qr_image;
        this.bankAccountName = transferGetResult.data.account_name;
        this.bankAccountNumber = transferGetResult.data.account_number;
        this.bankName = transferGetResult.data.bank_name;

        this.checkTransferStatusAsync();
      }
    }
  },
  methods: {
    formatVnPrice(price) {
      return formatPrice(price * 1000);
    },

    checkTransferStatusAsync() {
      var intervalId = setInterval(async () => {
        const result = await db.checkTransferedStatusAsync(this.orderObj.id);
        if (result.success == true){
          this.$router.push({ name: 'license'});
          clearInterval(intervalId);
        }
      }, 2000);
    }
  }
}
</script>

<style scoped>
.module-container {
  margin-top: 5px;
  padding: 0;
  margin-left: 10px;
  list-style-type: none;
}

.module-container li {
  padding-bottom: 10px;
}

.module-container li span {
  background: #4e4e4e;
  border-radius: 5px;
  color: white;
  padding: 5px 15px;
  font-size: 11px;
}
.my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
}
</style>