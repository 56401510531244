<template>
    <aside class="sidebar-mobile">
      <a href="#" class="sidebar-logo">
        <img src="../assets/logo-gpm-min.svg" alt="" class="logo"/>
      </a>
      <ul>
        <li v-for="(item, index) in MENU_CONFIG" :key="index" class="menu-item" :class="{ active: $route.name === item.link }" @click="toggleMenu(item)">
          <router-link :to="{name: item.link}"> 
            <i class="menu-icon" :class="item.icon"></i>
            <span>{{ $t(item.label) }}</span>
          </router-link>
        </li>
      </ul>
    </aside>
  </template>
  
  <script>
  import { MENU_CONFIG } from "@/constants/menu";
  export default {
    name: "LeftMenuMobile",
    data() {
      return {
        accessRouteNames: [],
        MENU_CONFIG,
      };
    },
    methods: {
      canAccessMenu(routeName) {
        if (this.$store.getters.getSystemRole == "ADMIN") return true;
  
        return this.accessRouteNames.includes(routeName);
      },
      toggleMenu(item) {
        this.$router.push(item?.link);
        this.$emit('toggle');
      },
    },
  };
  </script>
  <!-- <style>
  .el-drawer__header{
    margin-bottom: 0 !important;
  }</style> -->
  <style scoped>
   ::v-deep .side-bar-drawer  .el-drawer__header {
  margin-bottom: 0 !important;
}
  @import url('@/assets/style/side-bar-mobile.scss');
  </style>
  