import { createApp } from 'vue';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import en from './locales/en.json'
import vi from './locales/vi.json'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import "primevue/resources/themes/saga-blue/theme.css";
import 'primevue/resources/primevue.min.css';
import "primeicons/primeicons.css";
import './assets/tailwind.css'; 
import router from './routes';
import store from './store';
import ToastPlugin from 'vue-toast-notification';
import VTooltip from 'v-tooltip'
import { createI18n } from 'vue-i18n';
import sweetalert2Plugin from './plugins/sweetalert2';

import '../node_modules/nprogress/nprogress.css';
import 'vue-toast-notification/dist/theme-sugar.css';
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  silentTranslationWarn: true,
  fallbackLocale: 'vi',
  locale: 'vi',
  messages: {
    en,
    vi,
  },
  warnHtmlMessage: false,
});

const app = createApp(App);
app.use(router);
app.use(store);
app.use(ElementPlus);
app.use(ToastPlugin);
app.use(VTooltip)
app.use(i18n);
app.use(sweetalert2Plugin);
app.use(PrimeVue);
app.mount('#app');
if (navigator.serviceWorker) {
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    window.location.reload();
  });
}
