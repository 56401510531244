<template>
  <h5>{{ $t("license.title") }}</h5>
  <div class="warning-container mt-3">
    <div>
      <i class="fas fa-exclamation-triangle" style="color: #e84b00"></i>
      &nbsp;<span class="inline" v-html="warringContent"></span>
    </div>
  </div>

  <div class="info-container">
    <div v-html="instructionsContent"></div>
  </div>

  <div class="data-table mt-3">
    <table>
      <thead>
        <tr>
          <th>{{ $t("license.data_table1.license") }}</th>
          <th>{{ $t("license.data_table1.software") }}</th>
          <th>{{ $t("license.data_table1.buy_date") }}</th>
          <th>{{ $t("license.data_table1.device") }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr class="loading-row" v-if="inLoading">
          <td colspan="5">
            <div class="loading-spinner"></div>
          </td>
        </tr>
        <template v-else>
          <tr class="no-data-row" v-if="licenseObjs.length == 0">
            <td colspan="5">{{ $t("common.no_data") }}</td>
          </tr>
          <tr v-for="item in licenseObjs" v-bind:key="item.id">
            <td>
              <span class="copy" @click="copy(item.license)" title="Click để copy">
                <i class="far fa-copy" style="color: black !important"></i>
                {{ item.license }}
              </span>
              <span :title="$t('license.data_table1.title.change_license')" class="option"
                @click="changeLicenseAsync_event(item)"><i class="fas fa-sync-alt"></i></span>
              <br>
              <span style="color: #0080c0; margin: 0 5px; cursor: pointer; font-size: 0.9rem;" @click="showSubLicenseModal(item)">Quản lý
                Sub-license</span>
                <span style="color: #868686">|</span>
                <span style="color: #A80000; margin: 0 5px; cursor: pointer; font-size: 0.9rem;" @click="deleteSubLinceseAsync(item)">Xóa sub-license</span>
            </td>
            <td>
              <span style="color: #0080c0">{{ item.project.name }}</span>
            </td>
            <td>
              {{ formatDateStr(item.created_at) }}
            </td>
            <td>
              <span>
                <b style="color: red">{{ item.active_devices_count }}</b> /
                {{ item.limit_devices }}
                <span :title="$t('license.data_table1.title.reset')" class="option"
                  @click="resetDevicesAsync_event(item)"><i class="fas fa-sync-alt"></i></span>
              </span>
            </td>
            <td>
              <a :href="item.project.download_url" target="_blank" :title="$t('license.data_table1.title.download')"
                class="btn btn-success"><i class="fas fa-download"></i></a>
              &nbsp;
              <span :title="$t('license.data_table1.title.buy_more')" class="btn btn-warning"
                @click="showLicenseUpgradeModal(item)"><i class="fas fa-arrow-up"></i></span>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>

  <!-- Upgrade license -->
  <el-dialog v-model="isShowUpgradeModal" :title="$t('license.modal_upgrade.title')" width="80%"
    style="max-width: 700px" @close="isShowUpgradeModal = false">
    <div class="row modal-upgrade-body">
      <div class="col-md-6">
        <div class="pad15" style="max-width: 500px">
          <img :src="upgradeLicenseObj.transferImage" style="width: 100%" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="pad15">
          <label>
            <h3 style="color: #0080c0">
              {{ upgradeLicenseObj.projectObj?.name }}
            </h3>
          </label>
          <br /><br />
          <b style="color: #404040">{{
            $t("license.modal_upgrade.buy_more_modules")
          }}</b><br /><br />
          <div class="modules-container">
            <div v-for="item in upgradeLicenseObj.projectObj?.modules" :key="item.name" class="modules-container__item">
              <input type="checkbox" v-model="item.selected" :id="'module-' + item.name" :disabled="item.disabled" />
              <label :for="'module-' + item.name">{{ item.name }}</label>
            </div>
          </div>
          <br /><br />
          <div v-if="upgradeLicenseObj.upDeviceRules.length > 0">
            <b style="color: #404040">{{
              $t("license.modal_upgrade.buy_more_devices")
            }}</b><br />
            <input class="form-control" type="number" v-model="upgradeLicenseObj.upDevices" />
            <br /><br />
            <h4 style="color: #111111">
              {{ $t("license.modal_upgrade.total") }}:
              {{ formatVnPrice(upgradeLicenseObj.price) }}
            </h4>
            <br /><br />
          </div>

          <button class="btn btn-success" @click="generateQRAsync"
            :class="{ 'loading-btn w-full': upgradeLicenseObj.isCalculating }">
            <div class="spinner-border text-light spinner-border-sm" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <span>{{ $t("license.modal_upgrade.gen_qr") }}</span>
          </button>
          <br /><br />
        </div>
      </div>

      <div class="col-md-12">
        <div class="pad15">
          <p style="color: #0080c0">
            {{ $t("license.modal_upgrade.content") }}
          </p>
        </div>
      </div>
    </div>
  </el-dialog>

  <!-- Sub license-->
  <el-dialog v-model="isShowSubLicenseModal" :title="$t('license.modal_sublicense.title')" width="80%"
    style="max-width: 700px" @close="isShowSubLicenseModal = false">
    <div style="max-height: 600px; overflow-y: scroll">
      <small>{{ $t("license.modal_sublicense.description") }}</small>
      <br /><br />
      
      <div v-if="subLicenseObj.licenseObj" style="
          color: #0080c0;
          background: #d7ebff;
          margin-bottom: 15px;
          border-radius: 5px;
          padding: 15px;
        ">
        <small v-if="subLicenseObj.numberSubLicCanCreate > 0">{{ $t("license.modal_sublicense.can_create.previos")
          }}<b>{{ subLicenseObj.numberSubLicCanCreate }}</b>{{ $t("license.modal_sublicense.can_create.next") }}</small>
        <small v-if="subLicenseObj.numberSubLicCanCreate === 0" style="color: red">{{
          $t("license.modal_sublicense.can_not_create") }}</small>
      </div>
      <div v-show="subLicenseObj.numberSubLicCanCreate > 0">
        <button class="btn btn-success" @click="createSubLicensesEventAsync">
          {{ $t("license.modal_sublicense.create_sub") }}
        </button>
        <br /><br />
      </div>

      <div class="data-table table-respons">
        <table>
          <thead>
            <tr>
              <th>{{ $t("license.modal_sublicense.table.sub_license") }}</th>
              <th>{{ $t("license.modal_sublicense.table.login_ver") }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in subLicenseObj.subLicenseList" v-bind:key="item.id">
              <td>
                {{ item.sub_license }}
                <span class="copy" @click="copy(item.sub_license)" title="Click để copy">
                  <i class="far fa-copy" style="color: black !important"></i>
                  {{ item.license }}
                </span>
                <span :title="$t('license.modal_sublicense.table.title')" @click="changeSubLicenseEventAsync(item)"
                  class="option"><i class="fas fa-sync-alt"></i></span>
              </td>
              <td>
                <span v-if="item.device_id == null">0/1</span>
                <span v-else>1/1 ({{ item.machine_name }})</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  getLicensesAsync,
  resetLicenseAsync,
  changeLicenseAsync,
  getProjectAsync,
  createOrderAsync,
  getTransferInfoAsync,
  getSubLicensesAsync,
  createSubLicensesAsync,
  changeSubLicenseAsync,
  deleteAllSubLicenseAsync
} from "@/services/customerService";
import { formatDateString, formatPrice } from "@/helpers/strHelper";
import DOMPurify from "dompurify";

export default {
  name: "LicensePage",
  data() {
    return {
      licenseObjs: [],
      isShowUpgradeModal: false,
      isShowSubLicenseModal: false,

      subLicenseObj: {
        licenseObj: null,
        numberSubLicCanCreate: 0, // Calc number of sublicese can create
        subLicenseList: [],
      },

      upgradeLicenseObj: {
        transferImage: "/assets/img/loading_img.gif",
        bankName: "MB Bank",
        bankAccountNumber: "",
        bankAccountName: "",
        transferContent: "",
        upDevices: 0,
        licenseObj: null,
        projectObj: null,
        price: 0,
        upDeviceRules: [],
        upDeviceMax: 20,
        isCalculating: false,
      },

      inLoading: false,
    };
  },
  updated() {
    document.title = this.$t("license.document_title");
  },
  async mounted() {
    this.inLoading = true;
    const result = await getLicensesAsync();
    if (result.success === true) {
      this.licenseObjs = result?.data;
      // this.licenseObjs = [
      //   {
      //     license: "Tesst",
      //     project: { name: "hehehe" },
      //     created_at: "2021-01-01",
      //     limit_devices: 12,
      //     active_devices_count: 10,
      //   },
      //   {
      //     license: "Tesst",
      //     project: { name: "hehehe" },
      //     created_at: "2021-01-01",
      //     limit_devices: 12,
      //     active_devices_count: 10,
      //   },
      //   {
      //     license: "Tesst",
      //     project: { name: "hehehe" },
      //     created_at: "2021-01-01",
      //     limit_devices: 12,
      //     active_devices_count: 10,
      //   },
      //   {
      //     license: "Tesst",
      //     project: { name: "hehehe" },
      //     created_at: "2021-01-01",
      //     limit_devices: 12,
      //     active_devices_count: 10,
      //   },
      //   {
      //     license: "Tesst",
      //     project: { name: "hehehe" },
      //     created_at: "2021-01-01",
      //     limit_devices: 12,
      //     active_devices_count: 10,
      //   },
      //   {
      //     license: "Tesst",
      //     project: { name: "hehehe" },
      //     created_at: "2021-01-01",
      //     limit_devices: 12,
      //     active_devices_count: 10,
      //   },
      //   {
      //     license: "Tesst",
      //     project: { name: "hehehe" },
      //     created_at: "2021-01-01",
      //     limit_devices: 12,
      //     active_devices_count: 10,
      //   },
      // ];
    }

    this.inLoading = false;
  },

  methods: {
    changeLicenseAsync_event(item) {
      this.$swal
        .fire({
          title: this.$t("common.notify.title"),
          text: this.$t("license.confirm.change_license.content"),
          icon: "info",
          showCancelButton: true,
          cancelButtonText: this.$t("common.notify.reject"),
          confirmButtonText: this.$t("common.notify.confirm"),
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const result = await changeLicenseAsync(item.id);
            if (result.success === true) {
              item.license = result.data.license;
              this.$toast.success(
                this.$t("license.confirm.change_license.success")
              );
            }
          }
        });
    },

    async resetDevicesAsync_event(item) {
      this.$swal
        .fire({
          title: this.$t("common.notify.title"),
          text: this.$t("license.confirm.reset_device.content"),
          icon: "info",
          showCancelButton: true,
          cancelButtonText: this.$t("common.notify.reject"),
          confirmButtonText: this.$t("common.notify.confirm"),
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const result = await resetLicenseAsync(item.id);
            if (result.success === true) {
              item.active_devices_count = 0;
              this.$toast.success(
                this.$t("license.confirm.reset_device.success")
              );
            } else {
              this.$toast.error(result.message);
            }
          }
        });
    },

    async showLicenseUpgradeModal(licenseObj) {
      this.upgradeLicenseObj.licenseObj = licenseObj;
      const result = await getProjectAsync(
        this.upgradeLicenseObj.licenseObj.project.id
      );
      if (result?.success === true) {
        this.upgradeLicenseObj.projectObj = result.data;
        this.upgradeLicenseObj.price = 0;
        this.upgradeLicenseObj.upDevices = 0;

        let canUpgrade = false;

        // Module
        if (this.upgradeLicenseObj?.projectObj?.modules.length > 0) {
          const activeModules = JSON.parse(
            this.upgradeLicenseObj.licenseObj.active_modules
          );

          this.upgradeLicenseObj.projectObj.modules.forEach((module) => {
            if (activeModules.includes(module.name)) {
              module.disabled = true;
              module.selected = true;
            }
          });

          canUpgrade = true;
        }

        // Up devices
        if (this.upgradeLicenseObj?.projectObj?.meta_data) {
          const tmpObj = JSON.parse(
            this.upgradeLicenseObj.projectObj.meta_data
          );

          if (tmpObj.up_device_rules) {
            this.upgradeLicenseObj.upDeviceRules = tmpObj.up_device_rules;
            this.upgradeLicenseObj.upDeviceMax = tmpObj.up_device_max;
            canUpgrade = true;
          }
        }
        if (canUpgrade) this.isShowUpgradeModal = true;
        else this.$toast.warning(this.$t("license.confirm.show_upgrade"));
      }
    },

    // Sublicense -----------------------------------------------------------------------------------------------
    async showSubLicenseModal(licenseObj) {
      this.subLicenseObj.licenseObj = licenseObj;
      this.subLicenseObj.subLicenseList = [];
      this.isShowSubLicenseModal = true;

      const result = await getSubLicensesAsync(
        this.subLicenseObj.licenseObj.id
      );
      if (result.success === true) {
        this.subLicenseObj.numberSubLicCanCreate =
          licenseObj.limit_devices - result.data.length;
        this.subLicenseObj.subLicenseList = result.data;
      }
    },

    async createSubLicensesEventAsync() {
      if (!confirm(this.$t("license.confirm.create_sub_license"))) return;

      const result = await createSubLicensesAsync(
        this.subLicenseObj.licenseObj.id
      );
      if (result.success === true) {
        const regetResult = await getSubLicensesAsync(
          this.subLicenseObj.licenseObj.id
        );
        if (regetResult.success === true) {
          this.subLicenseObj.subLicenseList = regetResult.data;
        }
      }
    },

    async changeSubLicenseEventAsync(subLicenseObj) {
      const result = await changeSubLicenseAsync(subLicenseObj.id);
      if (result.success === true) {
        subLicenseObj.sub_license = result.data.sub_license;
      }
    },

    async deleteSubLinceseAsync(licenseObj){
      if (confirm('Delete all sub-license?')) {
        const result = await deleteAllSubLicenseAsync(licenseObj.id);
        if (result.success === true) {
          this.$toast.success('Delete sub-license success');
        }
      }
    },

    // Upgrade license ----------------------------------------------------------------------------------------
    async generateQRAsync() {
      if (
        this.upgradeLicenseObj.upDevices +
        this.upgradeLicenseObj.licenseObj.limit_devices >
        this.upgradeLicenseObj.upDeviceMax
      ) {
        this.$toast.warning(
          this.$t("license.confirm.gen_qr", {
            upDeviceMax_: this.upgradeLicenseObj.upDeviceMax,
          })
        );
        this.upgradeLicenseObj.upDevices = 0;
        this.upgradeLicenseObj.transferImage = "/assets/img/loading_img.gif";
        return;
      }

      // Create order obj
      this.upgradeLicenseObj.isCalculating = true;
      const selectedModules = this.upgradeLicenseObj.projectObj.modules
        .filter((item) => item.selected && !item.disabled)
        .map((item) => item.name);

      const orderObj = {
        project_id: this.upgradeLicenseObj.projectObj.id,
        project_module_names: selectedModules,
        modify_license_mode: "APPEND",
        modify_license_id: this.upgradeLicenseObj.licenseObj.id,
        modify_license_up_devices: this.upgradeLicenseObj.upDevices,
      };

      const result = await createOrderAsync(orderObj);

      if (result.success === true) {
        // Transfer info
        const transferGetResult = await getTransferInfoAsync(result.data.id);

        if (transferGetResult.success === true) {
          this.upgradeLicenseObj.transferContent =
            transferGetResult.data.transfer_content;

          this.upgradeLicenseObj.transferImage =
            transferGetResult.data.qr_image;
          this.upgradeLicenseObj.bankAccountName =
            transferGetResult.data.account_name;
          this.upgradeLicenseObj.bankAccountNumber =
            transferGetResult.data.account_number;
          this.upgradeLicenseObj.bankName = transferGetResult.data.bank_name;
          this.upgradeLicenseObj.isCalculating = false;
        }
      }
    },

    copy(item) {
      navigator.clipboard.writeText(item);
      this.$toast.success("Đã copy vào bộ nhớ");
    },

    formatDateStr(dateString) {
      return formatDateString(dateString);
    },
    formatVnPrice(price) {
      return formatPrice(price * 1000);
    },
  },
  computed: {
    warringContent() {
      return DOMPurify.sanitize(this.$t("license.warring"));
    },
    instructionsContent() {
      return DOMPurify.sanitize(this.$t("license.instruction"));
    },
  },
};
</script>

<style scoped>
.license-container {
  margin-top: 20px;
}

.warning-container {
  background: #ffffe3;
  padding: 15px;
  margin: 10px 0px;
  border-radius: 5px;
}

.info-container {
  background: #d7ebff;
  color: #0080c0;
  padding: 15px;
  margin: 10px 0px;
  border-radius: 5px;
}

.option {
  cursor: pointer;
  margin: 0px 0px 0px 5px;
}

.option i {
  margin-left: 5px;
  color: #0080c0;
}

.copy {
  cursor: pointer;
}

#upgrade-container {
  position: fixed;
  z-index: 999;
  max-width: 900px;
  widows: 80%;
  background: white;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  left: 50%;
  top: 100px;
  box-shadow: 0px 0px 10px #888888;
  transform: translate(-50%, 0%);
}

.pad15 {
  padding: 20px;
}

#sub-license-container {
  position: fixed;
  z-index: 999;
  max-width: 700px;
  width: 80%;
  background: white;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  left: 50%;
  top: 100px;
  box-shadow: 0px 0px 10px #888888;
  transform: translate(-50%, 0%);
}

#sub-license-container .pad15 {
  padding: 20px;
}

.modules-container {
  display: flex;
  flex-wrap: wrap;
}

.modules-container__item {
  padding: 5px 15px 5px 0px;
}

.modules-container__item input {
  margin-right: 6px;
}

.createSubLicenseBtn {
  color: #0080c0;
  font-size: 12px;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-upgrade-body {
  overflow: auto;
  max-height: 70vh;
}

.btn {
  padding: 3px 10px;
}

.btn-warning {
  padding: 3px 12px;
}

.table-respons table {
  min-width: unset;
}
</style>
