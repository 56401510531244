<template>
  <div class="auth bg-base d-flex flex-wrap">
    <div class="auth-left d-lg-block d-none">
      <div
        class="d-flex align-items-center flex-column h-100 justify-content-center"
      >
        <img  class="img-bg" src="../../assets/auth-img.png" alt="" />
      </div>
    </div>

    <div
      class="auth-right py-32 px-24 d-flex flex-column justify-content-center"
    >
      <div class="max-w-464-px mx-auto w-100">
        <div>
          <div class="mb-40 max-w-290-px">
            <img src="../../assets/logo-gpm.svg" alt="" style="height: 40px" />
          </div>
          <h4 class="mb-12">{{ $t("login.title") }}</h4>
          <p class="mb-32 text-secondary-light text-lg">
            {{ $t("login.content") }}
          </p>
        </div>
        <form @submit.prevent="submitLogin" @keyup.enter="submitLogin">
          <!-- <input type="text" v-model="loginToken" /> -->
          
          <div class="icon-field mb-16">
            <span class="icon top-50 translate-middle-y">
              <iconify-icon icon="mage:email"></iconify-icon>
            </span>
            <input
              type="email"
              class="form-control h-56-px bg-neutral-50 radius-12"
              placeholder="Email"
              v-model="email"
            />
          </div>
          <div class="position-relative mb-20">
            <div class="icon-field">
              <span class="icon top-50 translate-middle-y">
                <iconify-icon icon="solar:lock-password-outline"></iconify-icon>
              </span>
              <input
                type="password"
                class="form-control h-56-px bg-neutral-50 radius-12"
                id="your-password"
                v-model="password"
                :placeholder="$t('login.pass')"
              />
            </div>
            <span
              class="toggle-password ri-eye-line cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light"
              @click="togglePassword()"
            ></span>
            <div class="error mt-1" v-if="errorMsg">
              <span>{{ errorMsg }}</span>
            </div>
          </div>
          <div class="">
            <div class="d-flex justify-content-between gap-2">
              <div class="form-check style-check d-flex align-items-center">
                <input
                  class="form-check-input border border-neutral-300"
                  type="checkbox"
                  v-model="isRememberLogin"
                  id="remeber"
                />
                <label class="form-check-label" for="remeber"
                  > {{ $t("login.remember") }}
                </label>
              </div>
              <router-link :to="{name: 'forgot-password'}" class="text-primary-600 fw-medium">{{ $t("login.forgot") }} </router-link>
            </div>
          </div>
          <button
            type="submit"
            class="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32"
            :class="{ 'loading-btn': onSubmiting }"
          >
            <div
              class="spinner-border text-light spinner-border-sm"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <span>{{ $t("login.submit") }}</span>
          </button>
          <div class="mt-32 text-center text-sm flex justify-between auth-footer px-2">
            <div class="flex items-center col-sm-7 col-12">
              <span class="redirect-register">
                {{ $t("login.re_signup") }}
              <router-link :to="{name: 'register'}" class="text-primary-600 fw-semibold">&nbsp;{{ $t("login.sign_up") }}</router-link>
            </span>
            </div>
            <div class="mb-0 col-sm-5 col-12 box-language">
              <MultiLanguage />         
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { loginAsync } from "@/services/authService";
import MultiLanguage from '@/components/MultiLanguage.vue'

export default {
  name: "LoginPage",
  components: {  MultiLanguage },
  data() {
    return {
      title: "Login | User site",
      email: "",
      password: "",
      loginToken: "",
      onSubmiting: false,
      errorMsg: "",
      isRememberLogin: true,
      showPassword: false
    };
  },
  created() {
    document.title = this.title;
  },
  methods: {
      togglePassword() {
      this.showPassword = !this.showPassword;
      const passwordInput = document.querySelector('#your-password');
      passwordInput.type = this.showPassword ? 'text' : 'password';
    },
    // Login
    async submitLogin() {
      this.onSubmiting = true;

      // Validate
      if (this.loginToken.length == 0) {
        if (this.email.length == 0) {
          this.onSubmiting = false;
          this.errorMsg = this.$t('login.email_required');
          return;
        } else if (this.password.length < 6) {
          this.onSubmiting = false;
          this.errorMsg = this.$t('login.pass_invalid');
          return;
        }
      }

      // Login
      const loginResult = await loginAsync(
        this.email,
        this.password,
        this.loginToken
      );
      if (loginResult.success == true) {
        localStorage.setItem('isRememberLogin', this.isRememberLogin)
        localStorage.setItem('email', this.email)
        this.$router.push("/");
      } else {
        this.onSubmiting = false;
        this.errorMsg =
          loginResult?.message || "Login failed! Please try again!";
      }
    }
  },
  mounted(){
     if (localStorage.getItem('isRememberLogin'))
      this.isRememberLogin =
        String(localStorage.getItem('isRememberLogin')).toLowerCase() === 'true'
    if (this.isRememberLogin)
      this.email = localStorage.getItem('email')
  }
};
</script>
<style scoped>
@import url("@/assets/style/login.css");
</style>
