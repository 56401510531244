<template>
    <el-dialog
      v-model="isShowConfirmPasswordBox"
      :title="$t('affiliate_withdraw.modal_confirm.title')"
      width="35%"
      style="min-width:300px"
    >
      <form @submit.prevent="submitAsync">
        <div class="mb-2">
          <span class="cl-red">*</span>{{$t('affiliate_withdraw.modal_confirm.content')}}
        </div>
        <input v-model="password" type="text" class="form-control" required />
        <br />
      </form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="isShowConfirmPasswordBox = false">{{$t('affiliate_withdraw.modal_confirm.confirm.exit')}}</el-button>
          <el-button class="btn btn-success" @click="submitAsync"
            >{{$t('affiliate_withdraw.modal_confirm.confirm.accept')}}</el-button
          >
        </div>
      </template>
    </el-dialog>
  </template>
  
  <script>
  import { loginAsync, getCurrentUserAsync } from "@/services/authService";
  
  export default {
    name: "ConfirmPasswordBox",
    data() {
      return {
        password: "",
        isShowConfirmPasswordBox: false,
      };
    },
    methods: {
      showModal() {
        this.isShowConfirmPasswordBox = true;
        this.password = "";
      },
      async submitAsync() {
        const result = await getCurrentUserAsync();
        if (result.success === true) {
          const email = result.data.email;
  
          const loginResult = await loginAsync(email, this.password, "");
          if (loginResult.success) {
            this.$emit("confirmEvent", true);
            this.isShowConfirmPasswordBox = false;
            return;
          }
        }
        this.isShowConfirmPasswordBox = false;
        return this.$emit("confirmEvent", false);
      },
    },
  };
  </script>
  