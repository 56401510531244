import * as VueRouter from 'vue-router';
import NProgress from 'nprogress';

import LoginPage from '../pages/AuthPages/LoginPage.vue';
import RegisterPage from '../pages/AuthPages/RegisterPage.vue';
import PasswordRecoverPage from '../pages/AuthPages/PasswordRecoverPage.vue';
import PasswordResetPage from '../pages/AuthPages/PasswordResetPage.vue';
import MyAccountPage from '../pages/AuthPages/MyAccount.vue';
import NotFound from '../pages/NotFound.vue';

import HomePage from '../pages/User/HomePage.vue';

// Layout
import AuthLayout from '../layouts/AuthLayout.vue';
import UserLayout from '../layouts/UserLayout.vue';

import { verifyTokenAsync } from '../services/authService';


const routes = [
  {
    name: 'home-page',
    path: '/',
    component: HomePage,
    meta:
    {
      layout: UserLayout,
      requiresAuth: true
    },
  },
  {
    name: 'login',
    path: '/login',
    component: LoginPage,
    meta:
    {
      layout: AuthLayout,
      requiresAuth: false
    },
  },
  {
    name: 'register',
    path: '/register',
    component: RegisterPage,
    meta:
    {
      layout: AuthLayout,
      requiresAuth: false
    },
  },
  {
    name: 'forgot-password',
    path: '/forgot-password',
    component: PasswordRecoverPage,
    meta:
    {
      layout: AuthLayout,
      requiresAuth: false
    },
  },
  {
    name: 'reset-password',
    path: '/reset-password',
    component: PasswordResetPage,
    meta:
    {
      layout: AuthLayout,
      requiresAuth: false
    },
  },
  {
    name: 'my-account',
    path: '/my-account',
    component: MyAccountPage,
    meta:
    {
      layout: UserLayout,
      requiresAuth: true
    },
  },
  {
    name: 'not-found',
    path: '/not-found',
    component: NotFound,
    meta:
    {
      layout: AuthLayout,
      requiresAuth: false
    },
  }
];

// Dynamic route for admin folder

// Use require.context to dynamically import files from the "pages/Admin" folder
const pagesContext = require.context('../pages/User', true, /\.vue$/);

pagesContext.keys().forEach((fileName) => {
  // Get the component configuration
  const componentConfig = pagesContext(fileName);
  
  // fileName : ./Licenses/create.vue
  let routePath = fileName.replace('./', '').replace('.vue', '').replace('_', '-').replace('Page', '').toLowerCase(); // --> licenes/create
  if (routePath.includes('details')) routePath += '/:id';

  let componentName = fileName.replace('./', '').replace('/', '.').replace('.vue', '').replace('_', '-').replace('Page', '').toLowerCase(); // --> licenes.create
  componentName = componentName.replace('/', '-'); // --> licenes-create
  componentName = componentName.replace('.index', '');
  componentName = componentName.replace('.details', '');

  // Create a route object and add it to the routes array
  const route = {
    name: componentName,
    path: '/'+routePath, // Generate a path based on the component name
    component: componentConfig.default || componentConfig,
    meta:
    {
      layout: UserLayout,
      requiresAuth: true
    }
  };

  routes.push(route);
});

// console.log(routes);

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
});

// Handle not found url, middle, loading page effect
router.beforeEach(async (to, from, next) => {
  if (to.name)
    NProgress.start();

  if (to.matched.length == 0) {
    next('not-found');
    return;
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    const verifyTokenResult = await verifyTokenAsync();
    if (verifyTokenResult.success == true || to.name.includes('home')) {
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});


router.afterEach(() => {
  window.scrollTo({ top: 0, behavior: 'instant' });
  NProgress.done();
});

export default router;
