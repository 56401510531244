<!-- 
    Search select
    -------------------------------------------------------------------------------
    return selected id throw 'onchange' emnit, you can also use v-model

    <SearchSelect @onchange='getValue'></SearchSelect>

    methods: {
        getValue(id){

        }
    }
-->

<template>
    <div class="searchBox-container">
        <div class="searchBox-container__content" @click="isOpen = !isOpen">
            <span class="selected-content">{{selectedText}}</span>
            <span class="dropdown-btn"><i class="fas fa-caret-down"></i></span>
        </div>
        
        <div class="dropdown-container" v-show="isOpen">
            <ul>
                <li class="sticky-top search-container">
                    <input v-model="search" type="text" class="form-control" @input="handleSearch" v-on:keydown.enter="enterEvent($event)">
                </li>
                <li v-for="item in suggestList" v-bind:key="item[this.source.valueProperty]" @click="setSelectedId(item[this.source.valueProperty])">
                    {{ item.name }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SelectBox',
    props: {
        placeHolder: String
    },
    data(){
        return {
            search: '',
            isOpen: false,
            suggestList: [],
            selectedId: null,
            selectedText: '',
            source: {
                suggestSource: [],
                displayProperty: '',
                valueProperty: ''
            }
        }
    },
    created(){
        this.selectedText = this.placeHolder;
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    methods: {
        updateSuggestList(data, displayProperty, valueProperty, selectedId = undefined){
            this.source.suggestSource = data;
            this.source.displayProperty = displayProperty;
            this.source.valueProperty = valueProperty;
            this.suggestList = this.source.suggestSource;

            if (selectedId){ 
                this.setSelectedId(selectedId);
            }
        },

        setSelectedId(id){
            if (id === undefined) {
                this.selectedText = this.placeHolder;
                return;
            }
            this.isOpen = false;
            this.selectedText = this.source.suggestSource.find(item => item.id == id)[this.source.displayProperty];
            this.selectedId = id;

            this.$emit('update:modelValue', id);
            this.$emit('onchange', id);
        },
        async handleSearch(){
            this.suggestList = this.source.suggestSource.filter(item => item[this.source.displayProperty].toString().toLowerCase().includes(this.search.toLowerCase()));
        },

        // Press ENTER, select first suggest
        enterEvent(e){
            if (this.suggestList.length > 0){
                this.setSelectedId(this.suggestList[0][this.source.valueProperty], this.suggestList[0][this.source.displayProperty]);
            }
            e.preventDefault();
        },

        // Click outsite, hide dropdown container
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.isOpen = false;
            }
        }
    },
    watch: {
        placeHolder(newVal){
            this.selectedText = newVal
        }
  },
}
</script>

<style scoped>
.searchBox-container {
    border-radius: .25rem;
    border: 1px solid #ced4da;
    background: white;
    padding: 7px 5px 0px 10px;
    position: relative;
    height: 44px;
}

.searchBox-container__content {
    display: flex;
    cursor: pointer;
    overflow: hidden;
}

.selected-content {
    flex: 1;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
}

.search-container {
    background: white !important;
}

.dropdown-btn i {
    color: gray !important;
    font-size: 18px;
    margin-top: 2px;
}

.dropdown-btn:hover i {
    opacity: 0.7;
}

.dropdown-container {
    position: absolute;
    top: 34px;
    left: 0px;
    width: 100%;
    background: white;
    border: 1px solid #ced4da;
    border-radius: 5px;
    max-height: 400px;
    overflow-y: auto;
    z-index: 1995;
}

.dropdown-container ul {
    list-style-type: none;
    padding: 0;
}

.dropdown-container ul li {
    display: block;
    padding: 10px;
    cursor: pointer;
}

.dropdown-container ul li:hover {
    background: #EEEEEE;
}
</style>